import moment from "moment";
import { setYupLocale } from "utils/setYupLocale";
import { numberValidation } from "utils/utils";

export const useFormValidationSchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({});
};

export const calculateSchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({
    NetPurchasingValueOfVehicle: numberValidation(
      yup,
      t,
      true,
      null,
      120000000
    ),
    DeductibleGeneral: yup.number().nullable().required(),
    DeductibleBrokenGlass: yup.number().nullable().required(),
  });
};

export const insurerReportSchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({
    DateStart: yup.date().nullable().required(),
    DateEnd: yup.date().nullable().required(),
    // .when(
    //   "DateStart",
    //   (startDate, schema) =>
    //     startDate &&
    //     schema.min(startDate, t("ehm.form.error.endDateStartDate"))
    // ),
  });
};

export const companySchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({
    CompanyUid: yup.string().nullable().required().max(20),
    CompanyRegisterNumber: yup.string().nullable().required().max(20),
    CompanyName: yup.string().nullable().required().max(254),
    CompanyStreet: yup.string().nullable().required().max(100),
    CompanyHouseNumber: yup.string().nullable().required().max(12),
    CompanyCity: yup.string().nullable().required().max(50),
    CompanyPostalCode: yup.string().nullable().required().max(10),
    CompanyEmail: yup.string().email().nullable().notRequired().max(254),
    CompanyFlatNumber: yup.string().nullable().notRequired().max(10),
    CompanyTelephone: yup.string().nullable().notRequired().max(36),
  });
};

export const vehicleSchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({
    VehicleName: yup.string().nullable().required().max(50),
    VehicleVin: yup.string().nullable().required().max(36),
    VehicleBrand: yup.string().nullable().required().max(36),
    VehicleModel: yup.string().nullable().required().max(36),
    VehicleManufacturedOn: yup
      .date()
      .nullable()
      .required()
      .test(
        "valid-year",
        t("ehm.validation.dateInTheFuture"),
        function (value) {
          if (value && value > moment().startOf("day").toDate()) {
            return false;
          }
          return true;
        }
      ),
    IsNewVehicle: yup.string().nullable().required(),
    IsElectricalVehicle: yup.string().nullable().required(),
  });
};

export const policySchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({
    PolicyNumberErsteFrameContract: yup.string().nullable().required().max(20),
    PolicyStartDate: yup
      .date()
      .nullable()
      .required()
      .test("valid-year", t("ehm.validation.dateInThePast"), function (value) {
        if (value && value < moment().startOf("day").toDate()) {
          return false;
        }
        return true;
      }),
    PolicyEndDate: yup
      .date()
      .nullable()
      .notRequired()
      .test("valid-year", t("ehm.validation.dateInThePast"), function (value) {
        if (value && value < moment().startOf("day").toDate()) {
          return false;
        }
        return true;
      }),
    LeasingStartDate: yup
      .date()
      .nullable()
      .required()
      .test("valid-year", t("ehm.validation.dateInThePast"), function (value) {
        if (value && value < moment().startOf("day").toDate()) {
          return false;
        }
        return true;
      }),
    LeasingEndDate: yup
      .date()
      .nullable()
      .required()
      .test("valid-year", t("ehm.validation.dateInThePast"), function (value) {
        if (value && value < moment().startOf("day").toDate()) {
          return false;
        }
        return true;
      }),
    LeasingContractNumber: yup.string().nullable().required().max(20),
    TerritoryOfCoverage: yup.number().nullable().required(),
  });
};
export const createCertificateSchema = (yup, t) => {
  setYupLocale(t);
  return yup.object().shape({
    NetPurchasingValueOfVehicle: numberValidation(
      yup,
      t,
      true,
      null,
      120000000
    ),
    DeductibleGeneral: yup.mixed().nullable().required(),
    DeductibleBrokenGlass: yup.mixed().nullable().required(),
    VehicleName: yup.string().nullable().required().max(50),
    VehicleVin: yup.string().nullable().required().max(36),
    VehicleBrand: yup.string().nullable().required().max(36),
    VehicleModel: yup.string().nullable().required().max(36),
    VehicleManufacturedOn: yup
      .date()
      .nullable()
      .required()
      .test(
        "valid-year",
        t("ehm.validation.dateInTheFuture"),
        function (value) {
          if (value && value > moment().startOf("day").toDate()) {
            return false;
          }
          return true;
        }
      ),
    CompanyUid: yup.string().nullable().required().max(20),
    CompanyRegisterNumber: yup.string().nullable().required().max(20),
    CompanyName: yup.string().nullable().required().max(254),
    CompanyStreet: yup.string().nullable().required().max(100),
    CompanyHouseNumber: yup.string().nullable().required().max(12),
    CompanyCity: yup.string().nullable().required().max(50),
    CompanyPostalCode: yup.string().nullable().required().max(10),
    CompanyEmail: yup.string().email().nullable().notRequired().max(254),
    CompanyFlatNumber: yup.string().nullable().notRequired().max(10),
    CompanyTelephone: yup.string().nullable().notRequired().max(36),

    PolicyNumberErsteFrameContract: yup.string().nullable().required().max(20),
    PolicyStartDate: yup
      .date()
      .nullable()
      .required()
      .test("valid-year", t("ehm.validation.dateInThePast"), function (value) {
        if (value && value < moment().startOf("day").toDate()) {
          return false;
        }
        return true;
      }),
    PolicyEndDate: yup
      .date()
      .nullable()
      .notRequired()
      .test("valid-year", t("ehm.validation.dateInThePast"), function (value) {
        if (value && value < moment().startOf("day").toDate()) {
          return false;
        }
        return true;
      }),
    LeasingStartDate: yup
      .date()
      .nullable()
      .required()
      .test("valid-year", t("ehm.validation.dateInThePast"), function (value) {
        if (value && value < moment().startOf("day").toDate()) {
          return false;
        }
        return true;
      }),
    LeasingEndDate: yup.date().nullable().required(),
    LeasingContractNumber: yup.string().nullable().required().max(20),
    TerritoryOfCoverage: yup.mixed().nullable().required(),
  });
};
