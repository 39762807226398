import React, { Suspense, useEffect, useState } from "react";
import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";
import Logo from "../src/assets/GrECo_Logo_small.jpg";
import "./App.css";
// --- API
import { ErrorMessage, GlobalStyles, GrecoSpinner } from "@greco/components";
import { getUserPhotoSmall, logout } from "./api/GraphService";
// --- Greco Components
// --- Routes
import { routes } from "./config/routes";
// --- Application context
import { GrecoErrorPage } from "@greco/components";
import { ThemeProvider as StyledThemeProvider } from "styled-components";
import { getUserData } from "./api/UserDataLoader";
import AppHeader from "./components/Header/AppHeader";
// --- Theme
import { loadTheme } from "@fluentui/react";
import {
  FluentProvider,
  webDarkTheme,
  webLightTheme,
} from "@fluentui/react-components";
import { ThemeProvider as MuiThemeProvider } from "@mui/material";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import hu from "date-fns/locale/hu";

import { ToastContainer } from "react-toastify";
// --- Insights
import {
  AppInsightsContext,
  AppInsightsErrorBoundary,
} from "@microsoft/applicationinsights-react-js";
import i18next from "i18next";
import { useAppStore } from "store/AppStore";
import { darkStyledTheme, styledTheme } from "theme";
import { darkTheme, theme } from "theme/theme";
import { reactPlugin } from "./insights";

interface IState {
  headerConfig: any;
  panelIsOpen: boolean;
  user: any;
  error: string;
  loading: boolean;
  search: string;
  darkMode: boolean;
}

export const AppComponent = () => {
  const [
    { appState, taxonomyUad, taxonomy },
    { setAppStoreValue, loadUADTaxonomy, loadTaxonomy },
  ] = useAppStore();

  const [muiTheme, setMuiTheme] = useState(theme);
  const [webTheme, setWebTheme] = useState(webLightTheme);
  const getAllUserData = () => {
    setAppStoreValue("appState", { ...appState, loading: true });
    Promise.all([getUserData(), getUserPhotoSmall()])
      .then((values: any) => {
        return { ...values[0], photoSmall: values[1] };
      })
      .then((results: any) => {
        setAppStoreValue("appState", {
          ...appState,
          user: results,
          loading: false,
        });
      })
      .catch((err) => {
        setAppStoreValue("appState", {
          ...appState,
          loading: false,
        });
        errorHandler(err);
      });
  };

  const closeErrorMessage = (): void => {
    setAppStoreValue("appState", { ...appState, error: "" });
  };

  const panelStatus = (status: boolean): void => {
    setAppStoreValue("appState", { ...appState, panelIsOpen: status });
  };

  const searchOnChange = (value: string): void => {
    setAppStoreValue("appState", { ...appState, search: value });
  };

  const searchOnSearch = (value: string): void => {
    alert(value);
  };

  const errorHandler = (value: string): void => {
    setAppStoreValue("appState", { ...appState, error: value });
  };

  useEffect(() => {
    getAllUserData();
  }, []);

  useEffect(() => {
    if (appState.darkMode) {
      setMuiTheme(darkTheme);
      setWebTheme(webDarkTheme);
    } else {
      setMuiTheme(theme);
      setWebTheme(webLightTheme);
    }
  }, [appState.darkMode]);

  useEffect(() => {
    if (taxonomyUad !== null) return;
    loadUADTaxonomy();
  }, [loadUADTaxonomy, taxonomyUad]);

  useEffect(() => {
    if (taxonomy !== null) return;
    loadTaxonomy();
  }, [loadTaxonomy, taxonomy]);

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <Suspense fallback={<GrecoSpinner />}>
        <AppInsightsErrorBoundary
          onError={() => (
            <GrecoErrorPage
              title={(i18next.t("gos.error.title"), "Error")}
              message={i18next.t(
                "gos.error.description",
                "Uh, oh, something went wrong. Refresh is needed."
              )}
              dismissButton={null}
            />
          )}
          appInsights={reactPlugin}
        >
          <div>
            <FluentProvider theme={webTheme}>
              <StyledThemeProvider
                theme={appState.darkMode ? darkStyledTheme : styledTheme}
              >
                <MuiThemeProvider theme={muiTheme}>
                  {/* {appState.loading && <GrecoSpinner />} */}
                  {appState.error && (
                    <ErrorMessage
                      message={appState.error}
                      closeError={closeErrorMessage}
                    />
                  )}
                  <BrowserRouter>
                    <AppHeader
                      user={appState.user}
                      headerConfig={appState.headerConfig}
                      logo={Logo}
                      logout={logout}
                      panelStatus={panelStatus}
                      searchOnChange={searchOnChange}
                      searchOnSearch={searchOnSearch}
                      darkMode={(dark: boolean) => {
                        loadTheme(
                          dark ? darkStyledTheme : (styledTheme as any)
                        );
                        setAppStoreValue("appState", {
                          ...appState,
                          darkMode: dark,
                        });
                        setWebTheme(dark ? webDarkTheme : webLightTheme);
                      }}
                      tooltipStatusChange={(tooltipsStatus) => {
                        const checked = Boolean(
                          tooltipsStatus.target.ariaChecked === "true"
                            ? false
                            : true
                        );
                        localStorage.setItem("tooltipsStatus", String(checked));
                        setAppStoreValue("appState", {
                          ...appState,
                          headerConfig: {
                            ...appState.headerConfig,
                            tooltipsStatus: checked,
                          },
                        });
                      }}
                    />

                    <div
                      className={
                        appState.panelIsOpen
                          ? "app-wrapper app-wrapper--panelIsOpen"
                          : "app-wrapper"
                      }
                      style={{
                        backgroundColor: webTheme.colorNeutralBackground2,
                      }}
                    >
                      <Routes>
                        {Object.keys(routes).map((key) => {
                          const route = routes[key];
                          return (
                            <Route
                              key={key}
                              path={route.path}
                              element={<route.component />}
                              // exact
                            />
                          );
                        })}
                        <Route
                          path="*"
                          element={
                            <Navigate to={routes.listPage.path} replace />
                          }
                        />
                      </Routes>
                    </div>
                    <ToastContainer icon={false} />
                    <GlobalStyles />
                  </BrowserRouter>
                </MuiThemeProvider>
              </StyledThemeProvider>
            </FluentProvider>
          </div>
        </AppInsightsErrorBoundary>
      </Suspense>
    </AppInsightsContext.Provider>
  );
};

const App = () => (
  <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale={hu.code}>
    <AppComponent />
  </LocalizationProvider>
);

export default App;
