import React, { useMemo, useState } from "react";
import { Controller } from "react-hook-form";

import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";
import Skeleton from "react-loading-skeleton";
import { Body1Strong, Input } from "@fluentui/react-components";

type Props = {
  name: string;
  form: any;
  disabled?: boolean;
  isNegativeAllowed?: boolean;
  label: string;

  isFloat?: boolean;
  required: boolean;

  visible?: boolean;
  isLoading: boolean;
  contentAfter?: string;
  onBlur?: (e: any) => void;
};

const NumberInputField = ({
  name,
  form,
  label,
  disabled = false,
  isFloat = false,
  isNegativeAllowed,
  required = false,
  visible,
  isLoading,
  contentAfter = null,
  onBlur = null,
}: Props) => {
  const {
    control,
    getValues,
    getFieldState,
    formState: { errors },
  } = form;

  const parseInteger = useMemo(
    () => (string) => {
      const integerAccept = isNegativeAllowed
        ? /-?\d{1,3}(?: \d{3})*(?:,\d+)?/g
        : /\d{1,3}(?: \d{3})*(?:,\d+)?/g;
      return (string?.match(integerAccept) || []).join("");
    },
    [isNegativeAllowed]
  );

  const formatInteger = useMemo(() => {
    return (string) => {
      const parsed = parseInteger(string);
      const number = Number.parseInt(parsed, 10);
      if (Number.isNaN(number) && parsed === "-") {
        return parsed;
      }
      if (Number.isNaN(number)) {
        return "";
      }
      return number.toLocaleString("hu-HU");
    };
  }, [parseInteger]);

  const formatFloat = useMemo(() => {
    return (string) => {
      if (!string) return "";
      const parsed = parseFloat(string);
      return parsed.toLocaleString("hu-HU", {
        maximumFractionDigits: 4,
        minimumFractionDigits: 2,
      });
    };
  }, []);

  const localeStringToFloat = (str: string) => {
    if (!str) return null;
    let r = null;
    if (isFloat) {
      r = parseFloat(str.replaceAll(/\s/g, "").replaceAll(",", "."));
    } else {
      r = parseInt(str.replaceAll(/\s/g, "").replaceAll(",", "."), 10);
    }
    return r;
  };

  if (!visible) return null;

  return (
    <FieldContainer isTooltipHidden={false} tooltipText={label}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <>
              {disabled ? (
                <Body1Strong
                  style={{
                    marginLeft: "15px",
                    color: isLoading ? "#888888" : null,
                  }}
                >{`${field.value} ${
                  contentAfter ? contentAfter : ""
                }`}</Body1Strong>
              ) : (
                <Input
                  disabled={disabled || isLoading}
                  key={name}
                  name={name}
                  id={"input_" + name}
                  autoComplete="off"
                  value={
                    isFloat
                      ? formatFloat(field.value)
                      : formatInteger(field.value)
                  }
                  onBlur={(e) => {
                    field.onBlur();
                    onBlur && onBlur(e);
                  }}
                  onChange={(e, newValue) => {
                    let retVal = newValue.value;
                    let parsedNumber = null;
                    if (!isNegativeAllowed) {
                      retVal = retVal.replaceAll("-", "");
                    }
                    const flatNumber = retVal
                      .replaceAll(/\s/g, "")
                      .replaceAll(",", ".");
                    if (isFloat) {
                      parsedNumber = parseFloat(flatNumber);
                    } else {
                      parsedNumber = parseInt(flatNumber, 10);
                    }

                    if (
                      isNegativeAllowed &&
                      isNaN(parsedNumber) &&
                      newValue.value.startsWith("-")
                    ) {
                      retVal = localeStringToFloat(newValue.value);
                      field.onChange(retVal);
                      return;
                    }

                    retVal = parsedNumber.toLocaleString("hu-HU", {
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 10,
                    });
                    if (retVal !== "NaN") {
                      if (isFloat && newValue.value.endsWith(",")) {
                        retVal = retVal + ",";
                      }
                      // } else {
                      //   retVal = localeStringToFloat(retVal);
                      // }
                    } else {
                      retVal = localeStringToFloat("");
                    }

                    field.onChange(retVal);
                  }}
                  onFocus={(e) => {
                    e.target.select();
                  }}
                  contentAfter={contentAfter}
                />
              )}
              {errors && errors[name] ? (
                <ErrorMsg id={name + "_error"}>{errors[name].message}</ErrorMsg>
              ) : null}
            </>
          );
        }}
      />
    </FieldContainer>
  );
};

export default NumberInputField;
