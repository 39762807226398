import {
  ActionButton,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router";
import { routes } from "../../../config/routes";
import { Button } from "@fluentui/react-components";
import { AddFilled } from "@fluentui/react-icons";
import { useListPageStore, useListPageStoreState } from "store/ListPageStore";
import { useAppStore } from "store/AppStore";

type Props = {
  isActive?: boolean;
};

const NewOfferBtn = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { offerItemsLoadStatus } = useListPageStoreState();

  const loading = offerItemsLoadStatus === "loading";

  return (
    <Button
      disabled={loading}
      appearance="subtle"
      icon={<AddFilled fontSize={24} />}
      onClick={() => {
        navigate(routes.formPageNew.path);
      }}
    >
      {t("ehm.newCertificate.label")}
    </Button>
  );
};

const OfferBtn = () => <NewOfferBtn />;

export default OfferBtn;
