import axios, { CancelToken } from "axios";
import { ExpandedColumn } from "../types/columns";
import {
  Setting,
  SettingsResponse,
  TaxonomyUADResponse,
  TaxonomyResponse,
} from "../types/types";
import { createAzureFilterParam } from "../utils/azureSearchFilterParam";

const url = process.env.REACT_APP_BASE_URL;
const uadUrl = process.env.REACT_APP_BFF_HOST_UAD;

type LoadOffersArgs = {
  vehicleId?: number;
  vehicleStatusCode?: number;
  skip: number;
  top: number;
  search?: string;
  sort: any;
  showInvalidated: boolean;
  cancelToken: CancelToken;
  columns: ExpandedColumn[];
  clientIds?: number[];
};

export const LoadOffers = async (args: LoadOffersArgs) => {
  const { search, sort, top, skip, cancelToken, columns, showInvalidated } =
    args;

  let searchUrl = `${url}/offers?a=1`;

  // if (vehicleId) {
  //   searchUrl += `?fleetId=${vehicleId}`;
  // } else {
  //   searchUrl += "?a=1";
  // }
  if (!showInvalidated) {
    searchUrl += `&IsInvalidated=${showInvalidated}`;
  }

  searchUrl += `&searchCriteria=${search?.replace(
    /&/g,
    encodeURIComponent("&")
  )}*`;

  if (sort && sort.key !== "" && sort.dir !== "") {
    searchUrl += `&$orderby=${sort.key} ${sort.dir}`;
  }
  searchUrl += `&form=json`;
  searchUrl += `&$count=true`;
  searchUrl += `&$top=${top}&$skip=${skip}`;

  let filterStr = createAzureFilterParam(columns as any);

  if (filterStr.length > 0) {
    searchUrl += `&$filter=${filterStr}`;
  }

  const res = await axios.get<any>(searchUrl, {
    cancelToken,
  });

  return res;
};

export const getAppSettings = async (appID: number) => {
  return await axios.get<SettingsResponse>(`${uadUrl}/appsettings/${appID}`);
};

export const getUADTaxonomy = async () => {
  return await axios.get<TaxonomyUADResponse>(`${uadUrl}/taxonomy`);
};

export const getTaxonomy = async () => {
  return await axios.get<TaxonomyResponse>(`${url}/taxonomy`);
};

export const addAppSetting = async (setting: Partial<Setting>) => {
  return await axios.post<Setting[]>(`${uadUrl}/addappsettings`, [setting]);
};

export const deleteAppSetting = async (
  userAppSettingId: Setting["userAppSettingId"]
) => {
  return await axios.delete<Setting[]>(`${uadUrl}/deleteappsettings`, {
    data: [userAppSettingId],
  });
};

export const sendInsurerReportPolicies = async (payload) => {
  return await axios.post(`${url}/offers/insurer`, payload);
};

export const getOffer = async (offerId: number) => {
  return await axios.get<any>(`${url}/offers/${offerId}`);
};

export const updateAppSetting = async (setting: Setting) => {
  return await axios.patch<Setting[]>(`${uadUrl}/updateappsettings`, [setting]);
};

export const getOfferList = async (payload) => {
  return await axios.post(`${url}/LoadOfferListData`, payload);
};

export const calculate = async (payload) => {
  return await axios.post(`${url}/calculations`, payload);
};

export const createCertificate = async (payload) => {
  return await axios.post(`${url}/offers`, payload);
};

export type InvalidateOfferParams = {
  offerId: any;
};

export const invalidateOfferReq = async (payload) => {
  return await axios.patch<any>(`${url}/InvalidateOffer`, payload);
};

export const invalidateOffer = async (offerId: number) => {
  return await axios.patch<any>(`${url}/offers/invalidate/${offerId}`);
};
