import { TextField, useTheme } from "@fluentui/react";
import { Label, MaskedTextField } from "office-ui-fabric-react";
import React from "react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";
import Skeleton from "react-loading-skeleton";
import { Body1Strong, Input } from "@fluentui/react-components";

type Props = {
  form: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  visible?: boolean;
  mask?: string;
  isLoading: boolean;
  width?: string;
};

const TextInputField = ({
  name,
  label,
  required = false,
  disabled = false,
  visible = true,
  mask,
  form,
  isLoading,
  width = "100%",
}: Props) => {
  const theme = useTheme();

  const {
    control,
    formState: { errors },
  } = form;

  const { t } = useTranslation();

  if (!visible) return null;

  return (
    <FieldContainer isTooltipHidden={false} tooltipText={label}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {disabled ? (
              <Body1Strong
                style={{
                  marginLeft: "15px",
                  color: isLoading ? "#888888" : null,
                }}
              >
                {field.value}
              </Body1Strong>
            ) : (
              <Input
                style={{ width: width }}
                disabled={disabled || isLoading}
                key={name}
                name={name}
                id={"input_" + name}
                autoComplete="off"
                value={field.value?.toString() || ""}
                onChange={(e, newValue) => {
                  field.onChange(newValue.value);
                }}
                onFocus={(e) => {
                  e.target.select();
                }}
              />
            )}
            {errors && errors[name] ? (
              <ErrorMsg id={name + "_error"}>{errors[name].message}</ErrorMsg>
            ) : null}
          </>
        )}
      />
    </FieldContainer>
  );
};

export default TextInputField;
