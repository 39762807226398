import { ColumnOptionsButton, SearchResultsCount } from "@greco/components";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import {
  Flex,
  FlexRight,
  HorizontalSpacer,
  NavigationBar,
  StyledCheckbox,
} from "components/styled";
import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { useListPageStore } from "store/ListPageStore";
import { ColumnOptions } from "./ColumnOptions";
import { Search } from "./navigationbuttons/Search";
import { useAppStoreState } from "store/AppStore";
import { ExportExcelButton } from "./navigationbuttons/ExportExcelButton";
import { ExportPDFButton } from "./navigationbuttons/ExportPDFButton";
import { RefreshButton } from "./navigationbuttons/RefreshButton";

type Props = {
  refExcelExport: ExcelExport;
};

const ListPageNavigationFirstLine = ({ refExcelExport }: Props) => {
  const [
    {
      areAnyColumnsFiltered,
      count,
      searchTerm,
      showInvalidated,
      offerItemsLoadStatus,
    },
    { setListStoreValue },
  ] = useListPageStore();
  const { userRole } = useAppStoreState();
  const loading = offerItemsLoadStatus === "loading";
  const [isColumnOptionsOpen, setIsColumnOptionsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <NavigationBar style={{ zIndex: 333 }}>
      <RefreshButton />
      <Search
        placeholder="Search"
        value={searchTerm}
        onChange={(value) => setListStoreValue("searchTerm", value)}
      />
      <HorizontalSpacer width={10} />
      <ExportExcelButton refExcelExport={refExcelExport} />{" "}
      <HorizontalSpacer width={10} />
      <ExportPDFButton />
      <HorizontalSpacer width={10} />
      {userRole !== null && userRole !== "external" ? (
        <StyledCheckbox
          label={t("ehm.showInvalidated.label")}
          checked={showInvalidated}
          onChange={(e, checked) => {
            setListStoreValue("showInvalidated", checked);
          }}
          disabled={loading}
        />
      ) : null}
      <FlexRight>
        <Flex>
          <ColumnOptionsButton
            t={t}
            isActive={areAnyColumnsFiltered}
            onClick={() => {
              setIsColumnOptionsOpen(true);
            }}
          />
          <ColumnOptions
            isOpen={isColumnOptionsOpen}
            closePanel={() => {
              setIsColumnOptionsOpen(false);
            }}
          />
          <SearchResultsCount count={count} label={t("greco.searchResults")} />
        </Flex>
      </FlexRight>
    </NavigationBar>
  );
};

export default ListPageNavigationFirstLine;
