import React from "react";
import moment from "moment";
import { useTranslation } from "react-i18next";

interface Props {
  row: any;
}

const EndDate = ({ row }: Props) => {
  const { t } = useTranslation();

  return moment(row.PolicyEndDate).isValid() ? (
    <>{moment(row.PolicyEndDate).format("YYYY.MM.DD")} </>
  ) : (
    <i>{t("ehm.indefinite.label")}</i>
  );
};

export default EndDate;
