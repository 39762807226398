import React, { useContext } from "react";
import {
  ActionButton,
  mergeStyleSets,
  MessageBar,
  MessageBarType,
} from "office-ui-fabric-react";
import { firstLetterUpper } from "./utils";
import i18next from "i18next";
import { useAppStoreActions } from "store/AppStore";
import { toast } from "react-toastify";

interface INotify {
  notifyText: string;
  type: string;
  notifyCode: string;
  label: string;
}

export const errorHandlerClasses = mergeStyleSets({
  contentContainer: {
    whiteSpace: "initial",
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
  },
  copyTo: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  copyToIcon: { height: 17 },
  notificationMessage: {
    borderBottom: "2px solid #faf9f8",
    cursor: "pointer",
  },
});

interface IErrorHandler {
  value: string;
  type: string;
  label: string;
  fieldName?: string;
  errors?: any;
}

export const errorHandler = (props: IErrorHandler) => {
  let { value, type, label, fieldName, errors } = props;
  if (!value) return;
  let resultingErrorObj: INotify = {
    notifyCode: "",
    notifyText: value,
    type: type || "error", // default is error
    label: label,
  };
  toast(getErrorDOMObject(resultingErrorObj, fieldName, errors), {
    position: "top-right",
    pauseOnHover: true,
    className: "toast-default-background",
    autoClose: 5000,
    progressClassName: "toast-blue-loading",
    onClick: () => {},
  });
};

const getErrorDOMObject = (notify: INotify, fieldName: string, errors: any) => {
  const type: string = notify?.type.toLowerCase();
  let messageBarType: number = MessageBarType[type];

  return (
    <div key={notify.label} className={errorHandlerClasses.notificationMessage}>
      <ErrorHandlerMessage
        notify={notify}
        errors={errors}
        fieldName={fieldName}
      />
    </div>
  );
};

export const ErrorHandlerMessage = ({ notify, errors, fieldName }) => {
  const type: string = notify?.type.toLowerCase();
  let messageBarType: number = MessageBarType[type];
  const { removeNotification, setNotificationMessage } = useAppStoreActions();
  const typeFormated = i18next.t(`ehm.${firstLetterUpper(type)}`);

  const handleNotificationClick = (ev) => {
    ev.preventDefault();
    if (fieldName) {
      const element: any = Array.from(
        document.querySelectorAll("input, textarea")
      ).find((el) => {
        const inputName = el.getAttribute("name");
        return inputName === fieldName;
      });
      element.parentElement.scrollIntoView({ block: "center" });
      element?.focus();
    }
    if (notify.type !== "success") {
      removeNotification(notify.label);
    }
  };
  // if (notify.type !== "success") {
  //   setNotificationMessage({
  //     errors,
  //     fieldName,
  //     key: notify.label,
  //     handleNotificationClick,
  //     messageBarType,
  //     notify,
  //   });
  // }

  const isWarningMessage = messageBarType === 5; // use info color for warnings
  return (
    <MessageBar
      className="message-bar"
      messageBarType={messageBarType}
      // onDismiss={() => {
      //   removeNotification(notify.label);
      // }}
      isMultiline={false}
      styles={{
        root: {
          height: "100%",
          width: "100%",
          ".ms-MessageBar-innerText": {
            width: "100%",
          },
        },
      }}
    >
      <>
        <h2>{typeFormated}</h2>
        <div className={errorHandlerClasses.contentContainer}>
          <span>{notify.notifyText}</span>
          {
            //!isWarningMessage && (
            // <div className={errorHandlerClasses.copyTo}>
            //   <strong> {i18next.t("ehm.copyToClipboard")}</strong>:
            //   <ActionButton
            //     iconProps={{ iconName: "Copy" }}
            //     className={errorHandlerClasses.copyToIcon}
            //     onClick={(e) => {
            //       e.preventDefault();
            //       navigator.clipboard
            //         .writeText(notify.notifyText)
            //         .then(() => {})
            //         .catch(() => {});
            //       toast.success(i18next.t("ehm.copiedToClipboard"));
            //     }}
            //   ></ActionButton>
            // </div>
            //</>)
          }
        </div>
      </>
    </MessageBar>
  );
};
