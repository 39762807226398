import React from "react";
import { DatePicker } from "@fluentui/react";
import { useTheme } from "@emotion/react";
import { Controller } from "react-hook-form";
import { useTranslation } from "react-i18next";
import moment from "moment";
import "moment/locale/hu";

import { defaultDatePickerStrings } from "@fluentui/react";
import { FieldContainer } from "./FieldContainer";
import ErrorMsg from "./ErrorMsg";
import { Body1Strong } from "@fluentui/react-components";

const datePickerStyles = (theme) => ({
  root: {
    ".ms-TextField-fieldGroup": {
      borderTop: "1px solid rgb(209, 209, 209) !important",
      borderLeft: "1px solid rgb(209, 209, 209) !important",
      borderRight: "1px solid rgb(209, 209, 209) !important",
      borderBottom: "1px solid rgb(97, 97, 97) !important",
      borderRadius: "4px",
      // borderTop: "none !important",
      // borderRight: "none !important",
      // borderLeft: "none !important",
      background: "transparent !important",
    },
    ".ms-TextField-fieldGroup::after": {
      // borderTop: "none !important",
      // borderRight: "none !important",
      // borderLeft: "none !important",
    },
    ".ms-TextField-suffix": {
      marginRight: "30px",
      background: "none",
      cursor: "pointer",
    },
    ".ms-DatePicker-callout": {
      zIndex: 9999,
    },
    ".msDatePickerDisabled": {
      color: "#005aa1",
    },
    ".ms-TextField-fieldGroup input": {
      background: "transparent !important",
    },
  },
  container: {
    ".ms-DatePicker-goToday": {
      right: "14px !important",
    },
  },
  icon: { right: "0" },
});
type Props = {
  form: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  placeholder?: string;
  minDate?: Date;
  maxDate?: Date;
  includeTime?: boolean;
  visible?: boolean;
  isLoading: boolean;
};
const DatePickerField = (props: Props) => {
  const {
    name,
    label,
    required = false,
    disabled = false,
    placeholder = "",
    minDate,
    maxDate,
    includeTime = false,
    visible = true,
    form,
    isLoading,
  } = props;
  const theme = useTheme();
  const { t } = useTranslation();
  const {
    control,
    formState: { errors },
  } = form;

  const onFormatDate = (date) => {
    return includeTime
      ? moment(date).format("YYYY.MM.DD HH:mm")
      : moment(date).format("YYYY.MM.DD");
  };

  const onParseDateFromString = (dateStr) => {
    const formats = ["YYYY.MM.DD", "YYYY.M.D", "YYYY.MM.D", "YYYY.M.DD"];
    return moment(dateStr, formats, true).toDate();
  };

  if (!visible) return null;

  return (
    <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <>
            {disabled ? (
              <Body1Strong
                style={{
                  marginLeft: "15px",
                  color: isLoading ? "#888888" : null,
                }}
              >
                {moment(field.value).format(props["data-dateFormat"])}
              </Body1Strong>
            ) : (
              <DatePicker
                componentRef={field.ref}
                styles={datePickerStyles(theme)}
                allowTextInput
                value={field.value}
                onSelectDate={(date) => field.onChange(date)}
                parseDateFromString={onParseDateFromString}
                formatDate={onFormatDate}
                strings={defaultDatePickerStrings}
                disabled={isLoading}
                placeholder={placeholder}
                disableAutoFocus={true}
                minDate={minDate}
                maxDate={maxDate}
              />
            )}
            {errors[name] && (
              <ErrorMsg id={`${name}_error`}>{errors[name].message}</ErrorMsg>
            )}
          </>
        )}
      />
    </FieldContainer>
  );
};

export default DatePickerField;
