import { CommandBarButton } from "@fluentui/react";
import React from "react";
import clsx from "clsx";
import { useTranslation } from "react-i18next";

import { createPDFOffers } from "../../../utils/pdf";

import { useListPageStore } from "store/ListPageStore";
import { useAppStoreState } from "store/AppStore";
import { Button } from "@fluentui/react-components";
import { DocumentPdfRegular } from "@fluentui/react-icons";
import { columns } from "../columns";

export const ExportPDFButton = () => {
  const { t } = useTranslation();
  const [{ cols, offerItemsLoadStatus }, { loadOffersForReport }] =
    useListPageStore();
  const loading = offerItemsLoadStatus === "loading";
  const { taxonomy } = useAppStoreState();

  return (
    <Button
      disabled={loading}
      appearance="subtle"
      icon={<DocumentPdfRegular fontSize={24} />}
      onClick={() => {
        loadOffersForReport({
          onSuccess: (res) => {
            createPDFOffers({
              result: res,
              columns: cols
                .filter((c) => c.isVisible)
                .map((c) => {
                  const column = columns.find((col) => col.key === c.key);
                  return {
                    ...column,
                    width: c.width,
                  };
                }),
              taxonomy,
              t: t,
            }).open();
          },
          onError: () => {},
        });
      }}
    >
      {t("greco.exportPDF")}
    </Button>
  );
};
