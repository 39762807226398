import { TFunction } from "i18next";
import { ExpandedColumn } from "../types/columns";

export const transformVehiclesForExcel = ({
  vehicles,
  columns,
  taxonomy,
  t,
}: {
  vehicles: any[];
  columns: ExpandedColumn[];
  taxonomy: any;
  t: TFunction;
}) => {
  // const actualColumns = columns.filter(
  //   (c) => c.key !== "options"
  // );

  return vehicles.map((v) => {
    return columns
      .filter((c) => c.key !== "options")
      .reduce((transformedVehicle, c) => {
        if (!transformedVehicle["Status"])
          transformedVehicle["Status"] = v["Status"];
        if (c.excel && c.excel.render) {
          transformedVehicle[c.key] = c.excel.render({
            t,
            row: v,
            taxonomy,
          });
        }
        //
        else {
          transformedVehicle[c.key] = v[c.key];
        }

        return transformedVehicle;
      }, {});
  });
};
