import { createTheme } from "@mui/material";

export const theme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: "1rem",
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          "&.Mui-disabled": {
            color: "rgba(0, 0, 0, 0.7) !important",
            "-webkit-text-fill-color": "rgba(0, 0, 0, 0.7) !important",
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        input: {
          "&.Mui-disabled": {
            backgroundColor: "rgba(240, 240, 240, 0.7) !important",
            color: "rgba(0, 0, 0, 0.7) !important",
            "-webkit-text-fill-color": "rgba(0, 0, 0, 0.7) !important",
          },
        },
      },
    },
  },
  palette: {
    primary: {
      main: "#005aa1",
    },
    secondary: {
      main: "#f50057",
    },
    action: {
      // disabledBackground: "set color of background here",
      //disabledOpacity: null, // Set disabledOpacity to undefined to remove opacity from all disabled fields
      disabled: "#888888",
    },
  },
});

export const darkTheme = createTheme({
  components: {
    MuiPaper: {
      styleOverrides: {
        root: {
          padding: "1rem",
        },
      },
    },
  },
  palette: {
    mode: "dark",
    action: {
      // disabledBackground: "set color of background here",
      //disabledOpacity: null, // Set disabledOpacity to undefined to remove opacity from all disabled fields
      disabled: "#aaaaaa",
    },
  },
});
