import { StyledCheckbox } from "components/styled";
import { isArray } from "lodash";
import { Checkbox, Label, mergeStyleSets } from "office-ui-fabric-react";
import React from "react";
import { Controller } from "react-hook-form";

import { useTranslation } from "react-i18next";
import ErrorMsg from "./ErrorMsg";
import { FieldContainer } from "./FieldContainer";
import Skeleton from "react-loading-skeleton";
import { VSpace } from "components/Spacer";
import { useTheme } from "@emotion/react";
import { Body1Strong, Radio, RadioGroup } from "@fluentui/react-components";

const classes = mergeStyleSets({
  wrap: {
    height: "100%",
    display: "flex",
    alignItems: "center",
  },
});

type Props = {
  form: any;
  name: string;
  label: string;
  required?: boolean;
  disabled?: boolean;
  visible?: boolean;
  isLoading: boolean;
};

const CheckboxField = ({
  form,
  name,
  label,
  required = false,
  disabled = false,
  visible,
  isLoading,
}: Props) => {
  const { t } = useTranslation();
  const theme = useTheme();
  if (isLoading) return <Skeleton width="100%" height={67} />;

  const {
    control,
    formState: { errors },
  } = form;

  if (!visible) return null;
  return (
    <FieldContainer isTooltipHidden={disabled} tooltipText={label}>
      <Controller
        name={name}
        control={control}
        render={({ field }) => {
          return (
            <div>
              {disabled ? (
                <Body1Strong
                  style={{
                    marginLeft: "15px",
                    color: isLoading ? "#888888" : null,
                  }}
                >
                  {field.value ? t("greco.yes") : t("greco.no")}
                </Body1Strong>
              ) : (
                <>
                  <RadioGroup
                    layout="horizontal"
                    onChange={(e, data) => field.onChange(data.value)}
                    value={field.value + ""}
                  >
                    <Radio value="true" label={t("greco.yes")} />
                    <Radio value="false" label={t("greco.no")} />
                  </RadioGroup>
                </>
              )}
              {errors && errors[name] ? (
                <ErrorMsg id={name + "_error"}>{errors[name].message}</ErrorMsg>
              ) : null}
            </div>
          );
        }}
      />
    </FieldContainer>
  );
};

export default CheckboxField;
