import React from "react";
import Accordion from "components/accordion/Accordion";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { useFormPageStore, useFormPageStoreState } from "store/FormPageStore";
import { useTheme } from "@emotion/react";
import TextInputField from "components/controls9/TextInputField";
import DatePickerField from "components/controls9/DatePickerField";
import TaxonomyField from "components/controls9/TaxonomyField";
import DefaultButton from "components/DefaultButton";
import { resolverFn } from "utils/setYupLocale";
import { formatNumber } from "utils/number";
import * as yup from "yup";
import {
  createCertificateSchema,
  policySchema,
} from "../useFormValidationSchema";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { routes } from "config/routes";
import { StyledPrimaryButton } from "components/styled";
import { fieldToTab, getClasses } from "utils/utils";
import { MessageBarType, Nav } from "office-ui-fabric-react";
import {
  Body1,
  Button,
  Caption1,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  GriffelStyle,
  Input,
  MenuButton,
  Select,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import NumberInputField from "components/controls9/NumberInputField";
import { CertificateRegular } from "@fluentui/react-icons";
import { useAppStoreActions } from "store/AppStore";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};
const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});
const PolicySection = (props: Props) => {
  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setValue, getValues, setError, clearErrors } = props.form;
  const styles = useStyles();

  const formPageStore = useFormPageStore();
  const isLoading = props.isLoading;

  const [{ accordionCollapsedMap }, { setFormStoreValue }] = formPageStore;
  const { visibleMap, disabledMap, requiredMap } = useFormPageStoreState();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const theme = useTheme();
  const isDarkMode = theme.isDark;

  const classes = getClasses(theme, isDarkMode);
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  return (
    <>
      <Card
        className={styles.card}
        style={{
          padding: "30px",
          borderTop:
            "5px solid " +
            (getValues("InvalidatedByUserFullName")
              ? theme.palette.red
              : theme.palette.themePrimary),
        }}
      >
        <CardHeader
          header={
            <Body1>{/* <b>{t("ehm.calculationSection.label")}</b> */}</Body1>
          }
        />
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>
            {t("ehm.PolicyNumberErsteFrameContract.label")}*
          </div>
          <div style={{ marginTop: "6px", ...fieldStyle(1, 2) }}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="PolicyNumberErsteFrameContract"
              label={"ehm.PolicyNumberErsteFrameContract.label"}
              required={requiredMap.PolicyNumberErsteFrameContract}
              disabled={disabledMap.PolicyNumberErsteFrameContract}
              visible={visibleMap.PolicyNumberErsteFrameContract}
            />
          </div>
          <div style={labelStyle(2, 1)}>{t("ehm.PolicyStartDate.label")}*</div>
          <div style={fieldStyle(2, 2)}>
            <div style={{ display: "flex" }}>
              <DatePickerField
                isLoading={isLoading}
                form={props.form}
                name="PolicyStartDate"
                label={"ehm.PolicyStartDate.label"}
                data-format="YYYY.MM.DD"
                data-dateFormat="YYYY.MM.DD"
                required={requiredMap.PolicyStartDate}
                disabled={disabledMap.PolicyStartDate}
                visible={visibleMap.PolicyStartDate}
              />
            </div>
          </div>
          <div style={labelStyle(3, 1)}>{t("ehm.PolicyEndDate.label")}</div>
          <div style={fieldStyle(3, 2)}>
            <div style={{ display: "flex" }}>
              <DatePickerField
                isLoading={isLoading}
                form={props.form}
                name="PolicyEndDate"
                label={"ehm.PolicyEndDate.label"}
                data-format="YYYY.MM.DD"
                data-dateFormat="YYYY.MM.DD"
                required={requiredMap.PolicyEndDate}
                disabled={disabledMap.PolicyEndDate}
                visible={visibleMap.PolicyEndDate}
              />
            </div>
          </div>
          <div style={labelStyle(4, 1)}>
            {t("ehm.TerritoryOfCoverage.label")}*
          </div>
          <div style={fieldStyle(4, 2)}>
            <TaxonomyField
              isLoading={isLoading}
              form={props.form}
              name="TerritoryOfCoverage"
              label={"ehm.TerritoryOfCoverage.label"}
              taxonomyKey="EHMTerritoryOfCoverage"
              required={requiredMap.TerritoryOfCoverage}
              disabled={disabledMap.TerritoryOfCoverage}
              visible={visibleMap.TerritoryOfCoverage}
            />
          </div>
          <div style={labelStyle(5, 1)}>
            {t("ehm.LeasingContractNumber.label")}*
          </div>
          <div style={fieldStyle(5, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="LeasingContractNumber"
              label={"ehm.LeasingContractNumber.label"}
              required={requiredMap.LeasingContractNumber}
              disabled={disabledMap.LeasingContractNumber}
              visible={visibleMap.LeasingContractNumber}
            />
          </div>
          <div style={labelStyle(6, 1)}>{t("ehm.LeasingStartDate.label")}*</div>
          <div style={fieldStyle(6, 2)}>
            <div style={{ display: "flex" }}>
              <DatePickerField
                isLoading={isLoading}
                form={props.form}
                name="LeasingStartDate"
                label={"ehm.LeasingStartDate.label"}
                data-format="YYYY.MM.DD"
                data-dateFormat="YYYY.MM.DD"
                required={requiredMap.LeasingStartDate}
                disabled={disabledMap.LeasingStartDate}
                visible={visibleMap.LeasingStartDate}
              />
            </div>
          </div>
          <div style={labelStyle(7, 1)}>{t("ehm.LeasingEndDate.label")}*</div>
          <div style={fieldStyle(7, 2)}>
            <div style={{ display: "flex" }}>
              <DatePickerField
                isLoading={isLoading}
                form={props.form}
                name="LeasingEndDate"
                label={"ehm.LeasingEndDate.label"}
                data-format="YYYY.MM.DD"
                data-dateFormat="YYYY.MM.DD"
                required={requiredMap.LeasingEndDate}
                disabled={disabledMap.LeasingEndDate}
                visible={visibleMap.LeasingEndDate}
              />
            </div>
          </div>
        </div>
        <CardFooter style={{ flexDirection: "row-reverse", marginTop: "20px" }}>
          <Button
            appearance="subtle"
            icon={<CertificateRegular fontSize={16} />}
            onClick={() => {
              clearErrors();
              clearAllNotifications();

              resolverFn(policySchema(yup, t), getValues()).then((res) => {
                if (Object.keys(res.errors).length > 0) {
                  Object.keys(res.errors).forEach((key) => {
                    setError(key, res.errors[key]);
                    setNotificationMessage({
                      errors: [],
                      key: key,
                      messageBarType: MessageBarType.error,
                      fieldName: t("ehm." + key + ".label"),
                      handleNotificationClick: (e) => {
                        setFormStoreValue("selectedTab", fieldToTab(key));
                      },
                      notify: {
                        label: "label",
                        type: "error",
                        notifyCode: "notifyCode",
                        notifyText: (
                          <>
                            <b>{t("ehm." + key + ".label")}</b>
                            <br />
                            {res.errors[key].message}
                          </>
                        ),
                      },
                    });
                  });
                } else {
                  setFormStoreValue("disabledTab5", false);
                  setFormStoreValue("selectedTab", "tab5");
                }
              });
            }}
          >
            {t("ehm.EnterCreateCertificate.label")}
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default PolicySection;
