import { css, css as cssClass } from "@emotion/css";

import React from "react";
import { useTranslation } from "react-i18next";
import { useListPageStore } from "store/ListPageStore";
import { toast } from "components/FluentToast";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  DialogTrigger,
} from "@fluentui/react-components";

export const InvalidateDialog = () => {
  const [
    { invalidateOfferDialog, invalidateOfferStatus },
    { setListStoreValue, invalidateOffer, loadOffers },
  ] = useListPageStore();

  // const invalidateOfferDialog = useInvalidateOfferDialog();
  // const setInvalidateOfferDialog = useSetInvalidateOfferDialog();
  const { t } = useTranslation();
  // const invalidateOfferLoadStatusValue = useLoadStatusValue();
  // const invalidateOffer = useInvalidateOffer();
  // const isLoading = invalidateOfferLoadStatusValue === 'loading';

  const close = () => {
    setListStoreValue("invalidateOfferDialog", {
      isOpen: false,
      offer: null,
    });
  };

  const onSubmit = (data: any) => {
    // console.log("DATA",data);

    const payload: any = {
      appInfo: {
        dataObj: {
          offerId: data.offerId,
        },
      },
    };
    // console.log("PAYLOAD",payload);

    return invalidateOffer(
      payload
      // onSuccess: close
    );
  };

  return (
    <Dialog open={invalidateOfferDialog.isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("ehm.invalidateOffer.label")}</DialogTitle>
          <DialogContent>
            {t("ehm.invalidateOfferConfirmation.label")}
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={invalidateOfferStatus === "loading"}
              onClick={() => {
                setListStoreValue("invalidateOfferDialog", {
                  isOpen: false,
                  offer: null,
                });
              }}
            >
              {t("ehm.close.label")}
            </Button>
            <Button
              appearance="primary"
              disabled={invalidateOfferStatus === "loading"}
              onClick={() => {
                invalidateOffer(invalidateOfferDialog.offer.OfferId, () => {
                  loadOffers({ reset: true, delay: true });
                  setListStoreValue("invalidateOfferDialog", {
                    isOpen: false,
                    offer: null,
                  });
                  toast.success(t("greco.success"));
                });
              }}
            >
              {t("ehm.invalidateOffer.label")}
            </Button>
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
