import { TableSpinner } from "components/TableSpinner";
import React, { useRef } from "react";
import { useListPageStoreState } from "store/ListPageStore";
import { InvalidateDialog } from "./InvalidateOfferDialog";
import ListPageNavigation from "./ListPageNavigation";
import ListPageTable from "./ListPageTable";
import useListPage from "./useListPage";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import { ExportExcelOffers } from "./ExportExcelOffers";
import { ThemeProvider } from "@greco/components";
import { useAppStoreState } from "store/AppStore";
import { InsurerReportDialog } from "./InsurerReportDialog";

const ListPage = () => {
  useListPage();
  const refExcelExport = useRef<ExcelExport>();
  const { cols, offerItemsLoadStatus } = useListPageStoreState();
  const { appState } = useAppStoreState();

  return (
    <ThemeProvider isDarkMode={appState.darkMode}>
      <ListPageNavigation refExcelExport={refExcelExport?.current} />
      <ListPageTable />
      <ExportExcelOffers ref={refExcelExport} columns={cols} />
      {offerItemsLoadStatus === "loading" && <TableSpinner />}
      <InvalidateDialog />
      <InsurerReportDialog />
    </ThemeProvider>
  );
};

export default ListPage;
