import { FunctionComponent } from "react";
import { matchPath, useLocation } from "react-router-dom";
import ListPage from "pages/ListPageNew/ListPage";
import FormPage from "pages/FormPageNew/FormPage";
// const calculationPath = '/offer';
// const getCalculationPath = () => `/offer`;

const listPagePath = "/";

const formPagePath = "/form/:itemId";
const getFormPagePath = (offerId: number) => `/form/${offerId}`;
const formPageNewOfferPath = "/form";

const insurerReportPath = "/insurerReport";

type RouteDef = {
  key: RouteKey;
  path: string;
  component: FunctionComponent<any>;
  getPath: (...args: any[]) => string;
  matchPath: (loc: any) => any;
};

type Routes = {
  listPage: RouteDef;
  formPageNew: RouteDef;
};

export type RouteKey = keyof Routes;

export const routes = {
  listPage: {
    key: "listPage",
    path: listPagePath,
    component: ListPage,
    getPath: () => listPagePath,
    matchPath: (l) =>
      matchPath(
        {
          path: listPagePath,
          // exact: true
        },
        l.pathname
      ),
  },
  formPage: {
    key: "formPage",
    path: formPagePath,
    component: FormPage,
    getPath: getFormPagePath,
    matchPath: (l) =>
      matchPath(
        {
          path: formPagePath,
          // exact: true
        },
        l.pathname
      ),
  },
  formPageNew: {
    key: "formPageNewOffer",
    path: formPageNewOfferPath,
    component: FormPage,
    getPath: formPageNewOfferPath,
    matchPath: (l) =>
      matchPath(
        {
          path: formPageNewOfferPath,
          // exact: true
        },
        l.pathname
      ),
  },
};

export const useCurrentRoute = () => {
  const location = useLocation();
  return Object.values(routes).reduce((match: any | null, r) => {
    const matchResult = r.matchPath(location);
    if (!!matchResult) {
      return r.key;
    }
    return match;
  }, null);
};
