import i18next from "i18next";
import { Theme } from "@fluentui/react";
import { format, formatISO } from "date-fns";
import { toast } from "../components/FluentToast";
import { BackendError } from "../types/types";
import { mergeStyleSets } from "office-ui-fabric-react";

export const formatDateString = (date: string) => {
  return format(new Date(date), "yyyy.MM.dd");
};

export const stringToNumber = (str: string) => {
  let _str = str;
  _str = _str.replace(/\./g, "");
  _str = String(_str).replace(",", ".");
  return parseFloat(_str);
};
export const firstLetterUpper = (string: string) => {
  return string && string.charAt(0).toUpperCase() + string.slice(1);
};
export const sanitizeFileName = (s: string) => {
  return s.replace(/[^a-z0-9]/gi, "_").toLowerCase();
};

export const getClasses = (theme, isDarkMode) =>
  mergeStyleSets({
    navigation: {
      borderBottom: `1px solid ${theme.palette.neutralLight}`,
      height: "100%",
      "& .ms-Nav-groupContent": {
        marginBottom: "0",
      },
      ".ms-Nav-compositeLink.is-selected .ms-Button": {
        background: isDarkMode ? "#001321" : "#d0e7f8",
      },
    },
  });

export const getValueForServer = (type: string, value: any): any => {
  if (type === "date") {
    if (value === null || value === undefined) {
      return null;
    }
    return formatISO(value, { representation: "date" });
  }
  //
  else if (type === "number") {
    if (value === null || value === undefined) {
      return null;
    }
    return stringToNumber(value);
  }
  //
  else if (type === "selectlist") {
    if (value === null || value === undefined) {
      return null;
    }
    return value.value;
  } else if (type === "taxonomy") {
    if (value === null || value === undefined) {
      return null;
    }
    return value.value;
  } else if (type === "taxonomyapi") {
    if (value === null || value === undefined) {
      return null;
    }
    return value;
  }
  //
  else if (type === "taxonomylabel") {
    if (value === null || value === undefined) {
      return null;
    }
    if (typeof value === "string") {
      return value.trim();
    }
    //
    else {
      return value;
    }
  }
  //
  else if (type === "text") {
    if (value === null || value === undefined) {
      return null;
    }
    if (typeof value === "string") {
      return value.trim();
    }
    //
    else {
      return value;
    }
  }
  //
  else if (type === "checkbox") {
    return value;
  }
  return null;
};

export const fieldToTab = (field: string) => {
  switch (field) {
    case "NetPurchasingValueOfVehicle":
      return "tab1";
    case "DeductibleGeneral":
      return "tab1";
    case "DeductibleBrokenGlass":
      return "tab1";

    case "VehicleName":
      return "tab2";
    case "VehicleVin":
      return "tab2";
    case "VehicleManufacturedOn":
      return "tab2";
    case "VehicleBrand":
      return "tab2";
    case "VehicleModel":
      return "tab2";
    case "IsNewVehicle":
      return "tab2";
    case "IsElectricalVehicle":
      return "tab2";

    case "CompanyUid":
      return "tab3";
    case "CompanyRegisterNumber":
      return "tab3";
    case "CompanyName":
      return "tab3";
    case "CompanyStreet":
      return "tab3";
    case "CompanyHouseNumber":
      return "tab3";
    case "CompanyFlatNumber":
      return "tab3";
    case "CompanyTelephone":
      return "tab3";
    case "CompanyEmail":
      return "tab3";
    case "CompanyCity":
      return "tab3";
    case "CompanyPostalCode":
      return "tab3";

    case "PolicyNumberErsteFrameContract":
      return "tab4";
    case "PolicyStartDate":
      return "tab4";
    case "PolicyEndDate":
      return "tab4";
    case "LeasingStartDate":
      return "tab4";
    case "LeasingEndDate":
      return "tab4";
    case "LeasingContractNumber":
      return "tab4";
    case "TerritoryOfCoverage":
      return "tab4";
    default:
      return "tab5";
  }
};

export const toBase64 = (file: File) => {
  const reader = new FileReader();

  return new Promise<string>((resolve, reject) => {
    reader.onerror = () => {
      reader.abort();
      reject(new DOMException("Problem parsing file"));
    };

    reader.onload = () => {
      resolve(
        reader.result
          ? typeof reader.result === "string"
            ? reader.result.substring(reader.result.indexOf(",") + 1)
            : undefined
          : undefined
      );
    };
    reader.readAsDataURL(file);
  });
};

export const getSelectStyles = (hasError: boolean, theme: Theme): any => {
  return {
    control: (p, s) => {
      return {
        ...p,
        border: 0,
        borderRadius: 0,
        borderBottom: "1px solid",
        borderBottomColor: s.isFocused
          ? hasError
            ? "red"
            : "#005aa1"
          : "#ccc",
        outline: "none",
        "&:hover": {
          borderBottomColor: s.isFocused
            ? hasError
              ? "red"
              : "#005aa1"
            : "#ccc",
        },
        backgroundColor: s.isDisabled ? theme.palette.white : undefined,
      };
    },
  };
};

export const handleAxiosError = (err?: any, callbackFunction?: any) => {
  if (err?.isAxiosError) {
    const response = err.response;
    if (response) {
      const responseData = err.response.data;
      if (responseData) {
        const env = process.env.REACT_APP_ENVIRONMENT!;
        const developmentEnvironments = ["L", "D", "T"];
        const isDevOrTestEnv = developmentEnvironments.includes(env);
        if (Array.isArray(responseData)) {
          responseData.forEach((e: BackendError) => {
            const translatedMessageVariables = e.messageVariables.map(
              (item: string) => {
                return i18next.t(item);
              }
            );
            let message = e.messageCode;
            if (isDevOrTestEnv) {
              message = e?.exceptionMessage || e?.formatedMessage;
              toast.error(i18next.t(message));
              callbackFunction && callbackFunction(i18next.t(message));
            } else {
              toast.error(i18next.t(message, translatedMessageVariables));
              callbackFunction &&
                callbackFunction(
                  i18next.t(message, translatedMessageVariables)
                );
            }
          });
        }
        //
        else {
          let message = responseData.messageCode;
          if (message) {
            if (isDevOrTestEnv) {
              message =
                responseData?.exceptionMessage || responseData?.formatedMessage;
            }

            const translatedMessageVariables =
              responseData.messageVariables.map((item: string) => {
                return i18next.t(item);
              });
            toast.error(i18next.t(message, translatedMessageVariables));
            callbackFunction &&
              callbackFunction(i18next.t(message, translatedMessageVariables));
          } else {
            if (responseData.Message) {
              toast.error(responseData.Message);
              callbackFunction && callbackFunction(responseData.Message);
            } else {
              toast.error(responseData);
              callbackFunction && callbackFunction(responseData);
            }
          }
        }
      } else {
        toast.error(err?.toString());
        callbackFunction && callbackFunction(err?.toString());
      }
    } else {
      toast.error(err?.toString());
      callbackFunction && callbackFunction(err?.toString());
    }
  } else {
    toast.error(err?.toString());
    callbackFunction && callbackFunction(err?.toString());
  }
};
export const toNum = (value: string) => {
  return value && value !== ""
    ? Number.parseFloat(value.replaceAll(/\s/g, "").replaceAll(",", "."))
    : null;
};

export function numberValidation(
  yup: any,
  t: any,
  required: boolean,
  min: number | null,
  max: number | null
) {
  let retVal = yup
    .string()
    .nullable()
    .test("is-number", "Must be a number", (value) => {
      if (value === null || value === "") {
        return true;
      }
      // const germanNumberRegex = /^\d{1,3}(\.\d{3})*(,\d+)?$/;
      // if (!germanNumberRegex.test(value)) {
      //   return false;
      // }
      const huLocale = "hu-HU";
      const parsedNumber = Number(
        value.replaceAll(/\s/g, "").replace(",", ".")
      );
      const formattedNumber = parsedNumber.toLocaleString(huLocale);
      return !isNaN(parsedNumber); // && formattedNumber === value;
    })
    .transform((value) => (value === "" ? null : value));

  if (required) {
    retVal = retVal.required();
  }
  if (min) {
    retVal = retVal.test(
      "min",
      t("greco.form.errors.number.greaterorequal", { min }),
      (value) => {
        if (value === null) {
          return true;
        }
        const parsedNumber = Number(
          value.replaceAll(/\s/g, "").replace(",", ".")
        );
        return parsedNumber >= min;
      }
    );
  }
  if (max) {
    retVal = retVal.test(
      "max",
      t("greco.form.errors.number.lessorequal", { max }),
      (value) => {
        if (value === null) {
          return true;
        }
        const parsedNumber = Number(
          value.replaceAll(/\s/g, "").replace(",", ".")
        );
        return parsedNumber <= max;
      }
    );
  }
  return retVal;
}

export function flattenObject(obj, prefix = "") {
  return Object.keys(obj).reduce((acc, key) => {
    const newKey = prefix ? `${prefix}.${key}` : key;
    if (typeof obj[key] === "object" && obj[key] !== null) {
      Object.assign(acc, flattenObject(obj[key], newKey));
    } else {
      acc[key] = obj[key];
    }
    return acc;
  }, {});
}
