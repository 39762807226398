import { Button } from "@fluentui/react-components";
import { DocumentTableRegular } from "@fluentui/react-icons";
import { ExcelExport } from "@progress/kendo-react-excel-export";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useListPageStore } from "store/ListPageStore";

type Props = {
  refExcelExport: ExcelExport;
};

export const ExportExcelButton = ({ refExcelExport }: Props) => {
  const { t } = useTranslation();
  const [{ cols, offerItemsLoadStatus }, { loadOffersForReport }] =
    useListPageStore();
  const { taxonomy } = useAppStoreState();

  const [{ xlsVehicleItems }, { setListStoreValue }] = useListPageStore();

  const save = (component) => {
    if (!component) return;

    const options = component.workbookOptions();
    const data = component.props.data;
    const rows = options.sheets[0].rows;
    if (rows.length === 0) return;
    if (data.length === 0) return;
    for (let index = 1; index < rows.length; index++) {
      const item = data[index - 1];
      const row = rows[index];
      if (row.type === "data" && item["Status"] === "2") {
        row.cells.forEach((cell) => {
          cell.background = "#fed9cc";
        });
      }
    }
    component.save(options);
  };

  useEffect(() => {
    if (xlsVehicleItems) {
      save(refExcelExport);
    }
  }, [xlsVehicleItems]);

  return (
    <Button
      appearance="subtle"
      icon={<DocumentTableRegular fontSize={24} />}
      disabled={offerItemsLoadStatus === "loading"}
      onClick={() => {
        loadOffersForReport({
          onSuccess: (res) => {
            setListStoreValue("xlsVehicleItems", res);
            //save(refExcelExport);
            // refExcelExport.save(
            //   transformVehiclesForExcel({
            //     vehicles: res,
            //     columns: cols.filter((c) => c.isVisible),
            //     t,
            //     taxonomy,
            //   })
            // );
          },
          onError: () => {},
        });
      }}
    >
      {t("greco.exportExcel")}
    </Button>
  );
};
