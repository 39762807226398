import { Column, ColumnOptions as GrecoColumnOptions } from "@greco/components";
import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { ExpandedColumn } from "../../types/columns";
import { Setting } from "../../types/types";
import { useListPageStore, useListPageStoreState } from "store/ListPageStore";
import { columns } from "./columns";
import { useAppStoreState } from "store/AppStore";

const reorderColumn = (
  columns: ExpandedColumn[],
  index1: number,
  index2: number
) => {
  const clone = [...columns];
  clone.splice(index2, 0, clone.splice(index1, 1)[0]);
  return clone;
};

export const ColumnOptions = ({
  isOpen,
  closePanel,
}: {
  isOpen: boolean;
  closePanel: () => void;
}) => {
  const { taxonomyResponse } = useAppStoreState();
  const { t } = useTranslation();

  const [
    {
      tempColumns,
      settingsLoadStatus,
      selectedView,
      selectedViewColumns,
      columnOptionsSettings,
    },
    { setListStoreValue, deleteSetting, updateSetting, createSetting },
  ] = useListPageStore();
  const { taxonomyUad } = useAppStoreState();

  const applicationCodeId = taxonomyUad?.ApplicationCode?.find(
    (el) => el.code === "Application.EHM"
  ).id;

  const appSettingCodeId = taxonomyUad?.ApplicationSetting?.find(
    (el) => el.code === "AppSetting.ColumnOptions"
  ).id;
  const areSettingsLoading = settingsLoadStatus === "loading";
  const areSettingsLoaded = settingsLoadStatus !== "loading";

  const isViewLoading = areSettingsLoading;

  const transformedColumns = useMemo(() => {
    const transformedColumns = tempColumns?.map((c) => {
      if (c.key === "options" || c.key === "SelectedInsurer") {
        return {
          ...c,
          label: t(c.labelKey),
          isPresentInColumnOptions: false,
        };
      }
      return {
        ...c,
        label: t(c.labelKey),
      };
    });
    const fixedColumn = transformedColumns?.filter((column) => column.isFixed);

    const unfixedTransformedColumns = transformedColumns?.filter(
      (column) => !column.isFixed
    );

    return fixedColumn?.concat(unfixedTransformedColumns);
  }, [tempColumns, t]);

  if (!isOpen) return null;

  return (
    <div>
      <GrecoColumnOptions
        t={t}
        columns={transformedColumns as Column[]}
        isReady={areSettingsLoaded}
        isViewLoading={isViewLoading}
        closePanel={closePanel}
        onChangeView={(id) => {
          setListStoreValue("selectedViewId", id);
        }}
        onClickApply={() => {
          const visibleColumns = tempColumns.map((c) => {
            const column = columns.find((dc) => dc.key === c.key);
            return c.key === "options"
              ? { ...c, isVisible: true }
              : { ...c, pdf: column ? column?.pdf : c.pdf };
          });
          setListStoreValue("cols", visibleColumns);
          closePanel();
        }}
        onClickClearAllFilters={() => {
          const visibleColumns = tempColumns.map((c) => {
            const column = columns.find((dc) => dc.key === c.key);
            return c.key === "options"
              ? { ...c, isVisible: true }
              : { ...c, pdf: column ? column?.pdf : c.pdf };
          });
          setListStoreValue(
            "tempColumns",
            visibleColumns.map((c) => ({
              ...c,
              filter: columns.find((dc) => dc.key === c.key)?.filter,
            }))
          );
        }}
        onClickDeleteView={() => {
          deleteSetting(selectedView.userAppSettingId);
        }}
        onClickReset={() => {
          setListStoreValue("tempColumns", selectedViewColumns);
        }}
        onClickSaveView={() => {
          const visibleColumns = tempColumns.map((c) => {
            const column = columns.find((dc) => dc.key === c.key);
            return c.key === "options"
              ? { ...c, isVisible: true }
              : { ...c, pdf: column ? column?.pdf : c.pdf };
          });
          updateSetting({
            ...selectedView,
            userAppSettingValue: JSON.stringify(visibleColumns),
          });
        }}
        onCreateView={(data) => {
          const visibleColumns = tempColumns.map((c) => {
            const column = columns.find((dc) => dc.key === c.key);
            return c.key === "options"
              ? { ...c, isVisible: true }
              : { ...c, pdf: column ? column?.pdf : c.pdf };
          });
          const newSetting = {
            applicationCodeId: applicationCodeId,
            appSettingCodeId: appSettingCodeId,
            userAppSettingValue: JSON.stringify(visibleColumns),
            ...data,
          } as Setting;
          createSetting(newSetting);
          closePanel();
        }}
        onUpdateView={(data) => {
          updateSetting({
            ...selectedView,
            ...data,
          });
          closePanel();
        }}
        onDeselectAll={() => {
          const visibleColumns = tempColumns.map((c) => {
            const column = columns.find((dc) => dc.key === c.key);
            return c.key === "options"
              ? { ...c, isVisible: true }
              : { ...c, pdf: column ? column?.pdf : c.pdf };
          });

          setListStoreValue(
            "tempColumns",
            visibleColumns.map((c) => ({
              ...c,
              isVisible: c.isFixed ? true : false,
            }))
          );
        }}
        onSelectAll={() => {
          setListStoreValue(
            "tempColumns",
            tempColumns.map((c) => ({
              ...c,
              isVisible: true,
            }))
          );
        }}
        onReorderColumn={(src, dest) => {
          setListStoreValue(
            "tempColumns",
            reorderColumn(tempColumns, src, dest)
          );
        }}
        onToggleColumnVisibility={(columnKey) => {
          setListStoreValue(
            "tempColumns",
            tempColumns.map((c) => {
              if (c.key === columnKey) {
                return {
                  ...c,
                  isVisible: !c.isVisible,
                };
              }
              return c;
            })
          );
        }}
        selectedView={selectedView}
        setFilter={(columnKey, filter) => {
          const visibleColumns = tempColumns.map((c) => {
            const column = columns.find((dc) => dc.key === c.key);
            return c.key === "options"
              ? { ...c, isVisible: true }
              : { ...c, pdf: column ? column?.pdf : c.pdf };
          });
          setListStoreValue(
            "tempColumns",
            visibleColumns.map((c) => {
              if (c.key === columnKey) {
                return {
                  ...c,
                  filter,
                };
              }
              return c;
            })
          );
        }}
        taxonomy={taxonomyResponse as any}
        views={columnOptionsSettings}
      />
    </div>
  );
};
