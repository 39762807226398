import { ActionButton } from "office-ui-fabric-react";
import React from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import { routes } from "../../../config/routes";
import { Button } from "@fluentui/react-components";
import { DocumentBulletListMultipleRegular } from "@fluentui/react-icons";
import { useListPageStore, useListPageStoreActions } from "store/ListPageStore";

type Props = {
  isActive?: boolean;
};

const InsurerReportExportBtn = ({ isActive }: Props) => {
  const { t } = useTranslation();
  const [{ insurerReportDialog, offerItemsLoadStatus }, { setListStoreValue }] =
    useListPageStore();
  const loading = offerItemsLoadStatus === "loading";

  return (
    <Button
      disabled={loading}
      appearance="subtle"
      icon={<DocumentBulletListMultipleRegular fontSize={24} />}
      onClick={() => {
        setListStoreValue("insurerReportDialog", {
          isOpen: true,
          formData: insurerReportDialog.formData
            ? insurerReportDialog.formData + 1
            : 0,
        });
      }}
    >
      {t("ehm.ExportInsurerReport.label")}
    </Button>
  );
};

export default InsurerReportExportBtn;
