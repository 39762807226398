import React from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { ListContextMenuIconButton } from "../../components/styled";
// import { routes } from '../../../config/routes';
import * as API from "../../api/api";
import { useListPageStoreActions } from "store/ListPageStore";
import { useFormPageStoreActions } from "store/FormPageStore";
import { routes } from "config/routes";
import { useAppStoreActions, useAppStoreState } from "store/AppStore";

type Props = {
  offer?: any;
};

export const ContextButton = ({ offer }: Props) => {
  // console.log("OFFER",offer);

  const { t } = useTranslation();
  const navigate = useNavigate();
  const { setListStoreValue } = useListPageStoreActions();
  const { repeatOffer } = useFormPageStoreActions();
  const { taxonomy } = useAppStoreState();

  const onClickInvalidateOffer = async () => {
    setListStoreValue("invalidateOfferDialog", {
      isOpen: true,
      offer,
    });
    // const payload: any = {
    //   appInfo: {
    //     dataObj: {
    //       offerId: offer.offerId,
    //     },
    //   },
    // };
    // await API.invalidateOfferReq(payload);
  };

  const onClickRepeatOffer = async () => {
    repeatOffer(offer.OfferId, t, taxonomy, () => {
      navigate(routes.formPageNew.getPath);
    });
  };

  const items = [
    {
      key: "invalidateOffer",
      text: t("ehm.offers.contextMenu.invalidate"),
      iconProps: { iconName: "ErrorBadge" },
      disabled: false,
      onClick: onClickInvalidateOffer as any,
    },
    {
      key: "repeatOffer",
      text: t("ehm.offers.contextMenu.repeat"),
      iconProps: { iconName: "Refresh" },
      disabled: false,
      onClick: onClickRepeatOffer as any,
    },
  ];
  return (
    <ListContextMenuIconButton
      iconProps={{ iconName: "MoreVertical" }}
      menuIconProps={{ style: { display: "none" } }}
      menuProps={{
        items: offer.IsInvalidated ? [items[1]] : items,
      }}
    />
  );
};
