import React from "react";
import { Card, CardHeader } from "@fluentui/react-components";
import { Spinner } from "office-ui-fabric-react";

const SpinnerCard = () => {
  return (
    <Card
      style={{
        position: "relative",
        margin: "auto",
        marginTop: "20px",
        width: "768px",
        height: "700px",
      }}
    >
      <Spinner
        style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
        }}
      />
    </Card>
  );
};

export default SpinnerCard;
