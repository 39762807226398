import { Offer } from "types/types";

export const initialOffer: Offer = {
  NetPurchasingValueOfVehicle: null,
  DeductibleGeneral: null,
  DeductibleBrokenGlass: null,
  VehicleVin: null,
  VehicleName: null,
  VehicleManufacturedOn: null,
  IsNewVehicle: null,
  VehicleBrand: null,
  VehicleModel: null,
  IsElectricalVehicle: null,
  CompanyName: null,
  CompanyUid: null,
  CompanyRegisterNumber: null,
  CompanyStreet: null,
  CompanyHouseNumber: null,
  CompanyFlatNumber: null,
  CompanyPostalCode: null,
  CompanyCity: null,
  CompanyTelephone: null,
  CompanyEmail: null,
  PolicyNumberErsteFrameContract:
    process.env.REACT_APP_ERSTE_FRAME_CONTRACT_POLICY_NUMBER,
  PolicyStartDate: null,
  PolicyEndDate: null,
  LeasingStartDate: null,
  LeasingEndDate: null,
  LeasingContractNumber: null,
  TerritoryOfCoverage: null,

  PolicyPremium: null,
  OfferId: null,
  InsurerName: null,
  PathToOfferPdf: null,
  PathToAccessStatementPdf: null,
  Status: null,
  OfferRequestedAt: null,
  OfferRequestedByUserFullName: null,
};

export const initialVisibleMap = {
  NetPurchasingValueOfVehicle: true,
  DeductibleGeneral: true,
  DeductibleBrokenGlass: true,
  VehicleVin: true,
  VehicleName: true,
  VehicleManufacturedOn: true,
  IsNewVehicle: true,
  VehicleBrand: true,
  VehicleModel: true,
  IsElectricalVehicle: true,
  CompanyName: true,
  CompanyUid: true,
  CompanyRegisterNumber: true,
  CompanyStreet: true,
  CompanyHouseNumber: true,
  CompanyFlatNumber: true,
  CompanyPostalCode: true,
  CompanyCity: true,
  CompanyTelephone: true,
  CompanyEmail: true,
  PolicyNumberErsteFrameContract: true,
  PolicyStartDate: true,
  PolicyEndDate: true,
  LeasingStartDate: true,
  LeasingEndDate: true,
  LeasingContractNumber: true,
  TerritoryOfCoverage: true,
  calculate: true,
  createCertificate: true,
  downloadCertificate: false,
  InsurerName: true,
  PolicyPremium: true,
  PathToOfferPdf: false,
  PathToAccessStatementPdf: false,
  Status: false,
  OfferRequestedAt: false,
  OfferRequestedByUserFullName: true,
};

export const initialDisabledMap = {
  NetPurchasingValueOfVehicle: false,
  DeductibleGeneral: false,
  DeductibleBrokenGlass: true,
  VehicleVin: false,
  VehicleName: false,
  VehicleManufacturedOn: false,
  IsNewVehicle: false,
  VehicleBrand: false,
  VehicleModel: false,
  IsElectricalVehicle: false,
  CompanyName: false,
  CompanyUid: false,
  CompanyRegisterNumber: false,
  CompanyStreet: false,
  CompanyHouseNumber: false,
  CompanyFlatNumber: false,
  CompanyPostalCode: false,
  CompanyCity: false,
  CompanyTelephone: false,
  CompanyEmail: false,
  PolicyNumberErsteFrameContract: true,
  PolicyStartDate: false,
  PolicyEndDate: false,
  LeasingStartDate: false,
  LeasingEndDate: false,
  LeasingContractNumber: false,
  TerritoryOfCoverage: false,
  calculate: false,
  createCertificate: false,
  downloadCertificate: false,
  InsurerName: true,
  PolicyPremium: true,
  PathToOfferPdf: false,
  PathToAccessStatementPdf: false,
  Status: true,
  OfferRequestedAt: false,
  OfferRequestedByUserFullName: true,
};

export const initialRequiredMap = {
  NetPurchasingValueOfVehicle: true,
  DeductibleGeneral: true,
  DeductibleBrokenGlass: true,
  VehicleVin: true,
  VehicleName: true,
  VehicleManufacturedOn: true,
  IsNewVehicle: false,
  VehicleBrand: true,
  VehicleModel: true,
  IsElectricalVehicle: false,
  CompanyName: true,
  CompanyUid: true,
  CompanyRegisterNumber: true,
  CompanyStreet: true,
  CompanyHouseNumber: true,
  CompanyFlatNumber: false,
  CompanyPostalCode: true,
  CompanyCity: true,
  CompanyTelephone: false,
  CompanyEmail: false,
  PolicyNumberErsteFrameContract: true,
  PolicyStartDate: true,
  PolicyEndDate: true,
  LeasingStartDate: true,
  LeasingEndDate: true,
  LeasingContractNumber: true,
  TerritoryOfCoverage: true,
  InsurerName: false,
  PolicyPremium: false,
  PathToOfferPdf: false,
  PathToAccessStatementPdf: false,
  Status: false,
  OfferRequestedAt: false,
  OfferRequestedByUserFullName: false,
};
