import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";

interface Props {
  value: number;
  taxonomyKey: string;
}

const TaxonomyValue = ({ value, taxonomyKey }: Props) => {
  const { t } = useTranslation();
  const { taxonomy } = useAppStoreState();

  return t(taxonomy[taxonomyKey].byId[value].code);
};

export default TaxonomyValue;
