import React from "react";
import { ExpandedColumn } from "../../types/columns";
import { ContextButton } from "pages/ListPageNew/ContextButton";
import { CenteredCell, StyledLink, Truncate } from "components/styled";

import moment from "moment";
import { routes } from "config/routes";
import YesNoText from "components/YesNoText";
import TaxonomyValue from "components/TaxonomyValue";
import EndDate from "components/EndDate";

export const columns: ExpandedColumn[] = [
  {
    key: "OfferId",
    name: "OfferId",
    labelKey: "ehm.CertificateId.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return;
      return (
        <StyledLink to={routes.formPage.getPath(row.OfferId)}>
          {row.OfferId}
        </StyledLink>
      );
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.OfferId;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.OfferId };
      },
    },
  },

  {
    key: "options",
    name: "options",
    isResizable: true,
    isSortable: false,
    labelKey: "",
    maxWidth: 40,
    minWidth: 40,
    width: 0,
    isVisible: true,
    isFixed: true,
    onRender: (row) => {
      if (!row) return;
      return (
        <CenteredCell>
          <ContextButton offer={row} />
        </CenteredCell>
      );
    },
  },

  {
    key: "OfferRequestedAt",
    name: "OfferRequestedAt",
    labelKey: "ehm.OfferRequestedAt.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    onRender: (row) => {
      if (!row) return;
      return moment(row.OfferRequestedAt).format("YYYY.MM.DD");
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return moment(row.OfferRequestedAt).format("YYYY.MM.DD");
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: moment(row.OfferRequestedAt).format("YYYY.MM.DD") };
      },
    },
  },
  {
    key: "OfferRequestedByUserFullName",
    name: "OfferRequestedByUserFullName",
    labelKey: "ehm.OfferRequestedByUserFullName.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return;
      return row.OfferRequestedByUserFullName;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.OfferRequestedByUserFullName;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.OfferRequestedByUserFullName };
      },
    },
  },
  {
    key: "CompanyName",
    name: "CompanyName",
    labelKey: "ehm.CompanyName.label",
    isResizable: true,
    width: 300,

    // maxWidth: 170,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
    onRender: (row) => {
      if (!row) return;
      return row.CompanyName;
    },
  },
  {
    key: "CompanyUid",
    name: "CompanyUid",
    labelKey: "ehm.CompanyUid.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
    onRender: (row) => {
      if (!row) return;
      return row.CompanyUid;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.CompanyUid;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.CompanyUid };
      },
    },
  },
  {
    key: "PolicyStartDate",
    name: "PolicyStartDate",
    labelKey: "ehm.PolicyStartDate.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    onRender: (row) => {
      if (!row) return;
      return moment(row.PolicyStartDate).format("YYYY.MM.DD");
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return moment(row.PolicyStartDate).format("YYYY.MM.DD");
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: moment(row.PolicyStartDate).format("YYYY.MM.DD") };
      },
    },
  },
  {
    key: "PolicyEndDate",
    name: "PolicyEndDate",
    labelKey: "ehm.PolicyEndDate.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    onRender: (row) => {
      if (!row) return;
      return <EndDate row={row} />;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return moment(row.PolicyEndDate).isValid()
          ? moment(row.PolicyEndDate).format("YYYY.MM.DD")
          : t("ehm.indefinite.label");
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return {
          text: moment(row.PolicyEndDate).isValid()
            ? moment(row.PolicyEndDate).format("YYYY.MM.DD")
            : t("ehm.indefinite.label"),
        };
      },
    },
  },
  {
    key: "PolicyPremium",
    name: "PolicyPremium",
    labelKey: "ehm.PolicyPremium.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
    },

    onRender: (row) => {
      if (!row) return;
      const formatter = new Intl.NumberFormat("hu-HU", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return formatter.format(row.PolicyPremium);
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        const formatter = new Intl.NumberFormat("hu-HU", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        return formatter.format(row.PolicyPremium);
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        const formatter = new Intl.NumberFormat("hu-HU", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        return { text: formatter.format(row.PolicyPremium) };
      },
    },
  },
  {
    key: "VehicleName",
    name: "VehicleName",
    labelKey: "ehm.VehicleName.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
    onRender: (row) => {
      if (!row) return;
      return row.VehicleName;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.VehicleName;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.VehicleName };
      },
    },
  },
  {
    key: "VehicleBrand",
    name: "VehicleBrand",
    labelKey: "ehm.VehicleBrand.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
    onRender: (row) => {
      if (!row) return;
      return row.VehicleBrand;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.VehicleBrand;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.VehicleBrand };
      },
    },
  },
  {
    key: "VehicleModel",
    name: "VehicleModel",
    labelKey: "ehm.VehicleModel.label",
    isResizable: true,
    width: 140,

    // maxWidth: 170,
    // minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
    onRender: (row) => {
      if (!row) return;
      return row.VehicleModel;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.VehicleModel;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.VehicleModel };
      },
    },
  },
  {
    key: "VehicleVin",
    name: "VehicleVin",
    labelKey: "ehm.VehicleVin.label",
    isResizable: true,
    width: 180,

    maxWidth: 180,
    minWidth: 140,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row.VehicleVin;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.VehicleVin;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.VehicleVin };
      },
    },
  },
  {
    key: "VehicleManufacturedOn",
    name: "VehicleManufacturedOn",
    labelKey: "ehm.VehicleManufacturedOn.label",
    isResizable: true,
    width: 180,

    maxWidth: 190,
    minWidth: 150,
    // isFixed: true,
    isVisible: true,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    onRender: (row) => {
      if (!row) return;
      return moment(row.VehicleManufacturedOn).format("YYYY.MM.DD");
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return moment(row.VehicleManufacturedOn).format("YYYY.MM.DD");
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: moment(row.VehicleManufacturedOn).format("YYYY.MM.DD") };
      },
    },
  },
  {
    key: "CompanyCity",
    name: "CompanyCity",
    labelKey: "ehm.CompanyCity.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
    onRender: (row) => {
      if (!row) return;
      return row.CompanyCity;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.CompanyCity;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.CompanyCity };
      },
    },
  },
  {
    key: "CompanyEmail",
    name: "CompanyEmail",
    labelKey: "ehm.CompanyEmail.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row.CompanyEmail;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.CompanyEmail;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.CompanyEmail };
      },
    },
  },
  {
    key: "CompanyFlatNumber",
    name: "CompanyFlatNumber",
    labelKey: "ehm.CompanyFlatNumber.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row.CompanyFlatNumber;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.CompanyFlatNumber;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.CompanyFlatNumber };
      },
    },
  },
  {
    key: "CompanyHouseNumber",
    name: "CompanyHouseNumber",
    labelKey: "ehm.CompanyHouseNumber.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row.CompanyHouseNumber;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.CompanyHouseNumber;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.CompanyHouseNumber };
      },
    },
  },
  {
    key: "CompanyPostalCode",
    name: "CompanyPostalCode",
    labelKey: "ehm.CompanyPostalCode.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row.CompanyPostalCode;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.CompanyPostalCode;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.CompanyPostalCode };
      },
    },
  },
  {
    key: "CompanyRegisterNumber",
    name: "CompanyRegisterNumber",
    labelKey: "ehm.CompanyRegisterNumber.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
    onRender: (row) => {
      if (!row) return;
      return row.CompanyRegisterNumber;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.CompanyRegisterNumber;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.CompanyRegisterNumber };
      },
    },
  },

  {
    key: "CompanyStreet",
    name: "CompanyStreet",
    labelKey: "ehm.CompanyStreet.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    onRender: (row) => {
      if (!row) return;
      return row.CompanyStreet;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.CompanyStreet;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.CompanyStreet };
      },
    },
  },

  {
    key: "CompanyTelephone",
    name: "CompanyTelephone",
    labelKey: "ehm.CompanyTelephone.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row.CompanyTelephone;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.CompanyTelephone;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.CompanyTelephone };
      },
    },
  },

  {
    key: "DeductibleBrokenGlass",
    name: "DeductibleBrokenGlass",
    labelKey: "ehm.DeductibleBrokenGlass.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "EHMDeductibleBrokenGlass",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <TaxonomyValue
          value={row.DeductibleBrokenGlass}
          taxonomyKey="EHMDeductibleBrokenGlass"
        />
      );
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return t(
          taxonomy["EHMDeductibleBrokenGlass"].byId[row.DeductibleBrokenGlass]
            .code
        );
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return {
          text: t(
            taxonomy["EHMDeductibleBrokenGlass"].byId[row.DeductibleBrokenGlass]
              .code
          ),
        };
      },
    },
  },

  {
    key: "DeductibleGeneral",
    name: "DeductibleGeneral",
    labelKey: "ehm.DeductibleGeneral.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "EHMDeductibleGeneral",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <TaxonomyValue
          value={row.DeductibleGeneral}
          taxonomyKey="EHMDeductibleGeneral"
        />
      );
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return t(
          taxonomy["EHMDeductibleGeneral"].byId[row.DeductibleGeneral].code
        );
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return {
          text: t(
            taxonomy["EHMDeductibleGeneral"].byId[row.DeductibleGeneral].code
          ),
        };
      },
    },
  },

  {
    key: "InsurerName",
    name: "InsurerName",
    labelKey: "ehm.InsurerName.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row.InsurerName;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.InsurerName;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.InsurerName };
      },
    },
  },
  // {
  //   key: "InvalidatedAt",
  //   name: "InvalidatedAt",
  //   labelKey: "ehm.InvalidatedAt.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "date",
  //     date1: null,
  //     date2: null,
  //     operator: "isAfter",
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return moment(row.InvalidatedAt).isValid()
  //       ? moment(row.InvalidatedAt).format("YYYY.MM.DD")
  //       : "";
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return moment(row.InvalidatedAt).isValid()
  //         ? moment(row.InvalidatedAt).format("YYYY.MM.DD")
  //         : "";
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return {
  //         text: moment(row.InvalidatedAt).isValid()
  //           ? moment(row.InvalidatedAt).format("YYYY.MM.DD")
  //           : "",
  //       };
  //     },
  //   },
  // },

  // {
  //   key: "InvalidatedByUserFullName",
  //   name: "InvalidatedByUserFullName",
  //   labelKey: "ehm.InvalidatedByUserFullName.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "text",
  //     value: "",
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row.InvalidatedByUserFullName;
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return row.InvalidatedByUserFullName;
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return { text: row.InvalidatedByUserFullName };
  //     },
  //   },
  // },
  // {
  //   key: "InvalidatedByUsername",
  //   name: "InvalidatedByUsername",
  //   labelKey: "ehm.InvalidatedByUsername.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "text",
  //     value: "",
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row.InvalidatedByUsername;
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return row.InvalidatedByUsername;
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return { text: row.InvalidatedByUsername };
  //     },
  //   },
  // },
  // {
  //   key: "IsArchived",
  //   name: "IsArchived",
  //   labelKey: "ehm.IsArchived.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "boolean",
  //     value: null,
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return <YesNoText value={row.IsArchived} />;
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return row.IsArchived ? t("greco.yes") : t("greco.no");
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return { text: row.IsArchived ? t("greco.yes") : t("greco.no") };
  //     },
  //   },
  // },
  {
    key: "IsElectricalVehicle",
    name: "IsElectricalVehicle",
    labelKey: "ehm.IsElectricalVehicle.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    onRender: (row) => {
      if (!row) return;
      return <YesNoText value={row.IsElectricalVehicle} />;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.IsElectricalVehicle ? t("greco.yes") : t("greco.no");
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return {
          text: row.IsElectricalVehicle ? t("greco.yes") : t("greco.no"),
        };
      },
    },
  },
  // {
  //   key: "IsInvalidated",
  //   name: "IsInvalidated",
  //   labelKey: "ehm.IsInvalidated.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "boolean",
  //     value: null,
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return <YesNoText value={row.IsInvalidated} />;
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return row.IsInvalidated ? t("greco.yes") : t("greco.no");
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return row.IsInvalidated ? t("greco.yes") : t("greco.no");
  //     },
  //   },
  // },
  {
    key: "IsNewVehicle",
    name: "IsNewVehicle",
    labelKey: "ehm.IsNewVehicle.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "boolean",
      value: null,
    },
    onRender: (row) => {
      if (!row) return;
      return <YesNoText value={row.IsNewVehicle} />;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.IsNewVehicle ? t("greco.yes") : t("greco.no");
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.IsNewVehicle ? t("greco.yes") : t("greco.no") };
      },
    },
  },
  // {
  //   key: "LastModifiedAt",
  //   name: "LastModifiedAt",
  //   labelKey: "ehm.LastModifiedAt.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "date",
  //     date1: null,
  //     date2: null,
  //     operator: "isAfter",
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return moment(row.LastModifiedAt).format("YYYY.MM.DD");
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return moment(row.LastModifiedAt).format("YYYY.MM.DD");
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return { text: moment(row.LastModifiedAt).format("YYYY.MM.DD") };
  //     },
  //   },
  // },

  // {
  //   key: "LastModifiedByUserFullName",
  //   name: "LastModifiedByUserFullName",
  //   labelKey: "ehm.LastModifiedByUserFullName.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "text",
  //     value: "",
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row.LastModifiedByUserFullName;
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return row.LastModifiedByUserFullName;
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return { text: row.LastModifiedByUserFullName };
  //     },
  //   },
  // },
  // {
  //   key: "LastModifiedByUsername",
  //   name: "LastModifiedByUsername",
  //   labelKey: "ehm.LastModifiedByUsername.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "text",
  //     value: "",
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row.LastModifiedByUsername;
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return row.LastModifiedByUsername;
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return { text: row.LastModifiedByUsername };
  //     },
  //   },
  // },
  {
    key: "LeasingContractNumber",
    name: "LeasingContractNumber",
    labelKey: "ehm.LeasingContractNumber.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "text",
      value: "",
    },
    onRender: (row) => {
      if (!row) return;
      return row.LeasingContractNumber;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.LeasingContractNumber;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.LeasingContractNumber };
      },
    },
  },

  {
    key: "LeasingStartDate",
    name: "LeasingStartDate",
    labelKey: "ehm.LeasingStartDate.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    onRender: (row) => {
      if (!row) return;
      return moment(row.LeasingStartDate).format("YYYY.MM.DD");
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return moment(row.LeasingStartDate).format("YYYY.MM.DD");
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: moment(row.LeasingStartDate).format("YYYY.MM.DD") };
      },
    },
  },
  {
    key: "LeasingEndDate",
    name: "LeasingEndDate",
    labelKey: "ehm.LeasingEndDate.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "date",
      date1: null,
      date2: null,
      operator: "isAfter",
    },
    onRender: (row) => {
      if (!row) return;
      return moment(row.LeasingEndDate).format("YYYY.MM.DD");
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return moment(row.LeasingEndDate).format("YYYY.MM.DD");
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: moment(row.LeasingEndDate).format("YYYY.MM.DD") };
      },
    },
  },
  {
    key: "NetPurchasingValueOfVehicle",
    name: "NetPurchasingValueOfVehicle",
    labelKey: "ehm.NetPurchasingValueOfVehicle.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "number",
      number1: null,
      number2: null,
      operator: "gt",
      format: "integer",
      includeThousandSeparators: false,
    },

    onRender: (row) => {
      if (!row) return;
      const formatter = new Intl.NumberFormat("hu-HU", {
        maximumFractionDigits: 2,
        minimumFractionDigits: 2,
      });
      return formatter.format(row.NetPurchasingValueOfVehicle);
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        const formatter = new Intl.NumberFormat("hu-HU", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        return formatter.format(row.NetPurchasingValueOfVehicle);
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        const formatter = new Intl.NumberFormat("hu-HU", {
          maximumFractionDigits: 2,
          minimumFractionDigits: 2,
        });
        return { text: formatter.format(row.NetPurchasingValueOfVehicle) };
      },
    },
  },

  {
    key: "OfferRequestedByUsername",
    name: "OfferRequestedByUsername",
    labelKey: "ehm.OfferRequestedByUsername.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row.OfferRequestedByUsername;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.OfferRequestedByUsername;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.OfferRequestedByUsername };
      },
    },
  },
  {
    key: "PolicyNumberErsteFrameContract",
    name: "PolicyNumberErsteFrameContract",
    labelKey: "ehm.PolicyNumberErsteFrameContract.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",

    onRender: (row) => {
      if (!row) return;
      return row.PolicyNumberErsteFrameContract;
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return row.PolicyNumberErsteFrameContract;
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return { text: row.PolicyNumberErsteFrameContract };
      },
    },
  },
  // {
  //   key: "Status",
  //   name: "Status",
  //   labelKey: "ehm.Status.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "taxonomy",
  //     taxonomyKey: "EHMStatus",
  //     value: [],
  //     renderLabel: ({ taxonomy, t }) => {
  //       return (
  //         <>
  //           <Truncate>{t(taxonomy?.code)}</Truncate>
  //         </>
  //       );
  //     },
  //   },

  //   onRender: (row) => {
  //     if (!row) return;
  //     return <TaxonomyValue value={row.Status} taxonomyKey="EHMStatus" />;
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return t(taxonomy["EHMStatus"].byId[row.Status].code);
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return {
  //         text: t(taxonomy["EHMStatus"].byId[row.Status].code),
  //       };
  //     },
  //   },
  // },
  // {
  //   key: "StatusDescription",
  //   name: "StatusDescription",
  //   labelKey: "ehm.StatusDescription.label",
  //   isResizable: true,
  //   width: 180,
  //   maxWidth: 180,
  //   minWidth: 140,
  //   isVisible: false,
  //   isSortable: true,
  //   iconName: "Sort",
  //   filter: {
  //     type: "text",
  //     value: "",
  //   },
  //   onRender: (row) => {
  //     if (!row) return;
  //     return row.StatusDescription;
  //   },
  //   excel: {
  //     render: ({ t, taxonomy, row }) => {
  //       return row.StatusDescription;
  //     },
  //   },
  //   pdf: {
  //     render: ({ row, t, taxonomy }) => {
  //       return { text: row.StatusDescription };
  //     },
  //   },
  // },
  {
    key: "TerritoryOfCoverage",
    name: "TerritoryOfCoverage",
    labelKey: "ehm.TerritoryOfCoverage.label",
    isResizable: true,
    width: 180,
    maxWidth: 180,
    minWidth: 140,
    isVisible: false,
    isSortable: true,
    iconName: "Sort",
    filter: {
      type: "taxonomy",
      taxonomyKey: "EHMTerritoryOfCoverage",
      value: [],
      renderLabel: ({ taxonomy, t }) => {
        return (
          <>
            <Truncate>{t(taxonomy?.code)}</Truncate>
          </>
        );
      },
    },
    onRender: (row) => {
      if (!row) return;
      return (
        <TaxonomyValue
          value={row.TerritoryOfCoverage}
          taxonomyKey="EHMTerritoryOfCoverage"
        />
      );
    },
    excel: {
      render: ({ t, taxonomy, row }) => {
        return t(
          taxonomy["EHMTerritoryOfCoverage"].byId[row.TerritoryOfCoverage].code
        );
      },
    },
    pdf: {
      render: ({ row, t, taxonomy }) => {
        return {
          text: t(
            taxonomy["EHMTerritoryOfCoverage"].byId[row.TerritoryOfCoverage]
              .code
          ),
        };
      },
    },
  },
];
