import {
  CommandBar,
  CommandBarButton,
  CommandButton,
  ICommandBarItemProps,
} from "@fluentui/react";
import { Flex, FlexRight, NavigationBar } from "components/styled";
import React from "react";
import { useTranslation } from "react-i18next";
import { useAppStoreState } from "store/AppStore";
import { useListPageStoreState } from "store/ListPageStore";
import InsurerReportExportBtn from "./navigationbuttons/InsurerReportExportBtn";
import OfferBtn from "./navigationbuttons/NewOffer";
import { DocumentRibbonRegular } from "@fluentui/react-icons";

const ListPageNavigationSecondLine = () => {
  const { userRole } = useAppStoreState();
  const { offerItemsLoadStatus } = useListPageStoreState();
  const { t } = useTranslation();

  const isLoading = offerItemsLoadStatus === "loading";

  const insuranceConditionsMenuProps = {
    items: [
      {
        key: "doc1",
        text: t("ehm.insuranceConditionsDoc1.label"),
        preferMenuTargetAsEventTarget: true,
        onClick: (
          ev?:
            | React.MouseEvent<HTMLElement, MouseEvent>
            | React.KeyboardEvent<HTMLElement>
            | undefined
        ) => {
          window.open(
            process.env.REACT_APP_GENERAL_INSURANCE_CONDITIONS_DOC_URL,
            "_blank"
          );
        },
      },
      {
        key: "doc2",
        text: t("ehm.insuranceConditionsDoc2.label"),
        preferMenuTargetAsEventTarget: true,
        onClick: (
          ev?:
            | React.MouseEvent<HTMLElement, MouseEvent>
            | React.KeyboardEvent<HTMLElement>
            | undefined
        ) => {
          window.open(
            process.env.REACT_APP_GENERAL_INSURANCE_CONDITIONS_DOC2_URL,
            "_blank"
          );
        },
      },
    ],
  };

  return (
    <NavigationBar>
      <OfferBtn />
      {userRole !== null && userRole !== "external" ? (
        <InsurerReportExportBtn />
      ) : null}
      <FlexRight>
        <Flex>
          <CommandBarButton
            onRenderIcon={() => <DocumentRibbonRegular fontSize={24} />}
            menuProps={insuranceConditionsMenuProps}
            style={{ marginRight: "10px", height: "42px" }}
            disabled={isLoading}
            text={t("ehm.insuranceConditions.label")}
          />
        </Flex>
      </FlexRight>
    </NavigationBar>
  );
};

export default ListPageNavigationSecondLine;
