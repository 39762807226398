import Axios, { AxiosError } from "axios";
import moment from "moment";
import { Setting } from "types/types";
import { handleAxiosError } from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./ListPageStore";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const loadListData =
  () =>
  async ({ setState, getState }) => {
    if (getState().listDataLoadStatus === "loading") return;
    try {
      setState({
        listDataLoadStatus: "loading",
      });
      const res = await API.getOfferList({
        appInfo: {
          dataObj: {},
        },
      });
      setState({
        listData: res.data.appInfo.dataObj,
        listDataLoadStatus: "success",
      });
    } catch (error) {
      handleAxiosError(error);
      setState({
        listDataLoadStatus: "error",
      });
    }
  };

export const loadOffers =
  (payload, onSuccess?) =>
  async ({ setState, getState }) => {
    const { reset = false, delay = false, skip = 0, top = 40 } = payload;
    const cancelSource = Axios.CancelToken.source();
    // if (cancelSource && reset) {
    //   cancelSource.cancel();
    // }

    try {
      if (getState().offerItemsLoadStatus === "loading") return;
      setState({
        offerItemsLoadStatus: "loading",
      });
      const response = await API.LoadOffers({
        columns: getState().cols,
        skip,
        top,
        showInvalidated: getState().showInvalidated,
        sort: getState().sort,
        search: getState().searchTerm,
        cancelToken: cancelSource.token,
      });
      let newOffers = response.data.value;
      if (reset === false) {
        newOffers = [...getState().vehicleItems, ...response.data.value];
      }
      setState({
        vehicleItems: hydrateOffers(newOffers),
        count: response.data["@odata.count"],
        offerItemsLoadStatus: "success",
      });
      onSuccess && onSuccess(response.headers["usertype"]);
    } catch (error: any) {
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error);
        setState({
          offerItemsLoadStatus: "error",
        });
      }
    }
  };

export const loadOffersForReport =
  (payload) =>
  async ({ setState, getState }) => {
    const { onSuccess, onError } = payload;
    const cancelSource = Axios.CancelToken.source();

    try {
      if (getState().offerItemsLoadStatus === "loading") return;

      const response = await API.LoadOffers({
        columns: getState().cols,
        skip: 0,
        top: 1000000,
        showInvalidated: getState().showInvalidated,
        sort: getState().sort,
        search: getState().searchTerm,
        cancelToken: cancelSource.token,
      });
      let newOffers = response.data.value;
      setState({
        offerItemsLoadStatus: "success",
      });
      onSuccess && onSuccess(newOffers);
    } catch (error: any) {
      onError && onError(error);
      if (error.code !== "ERR_CANCELED") {
        handleAxiosError(error);
        setState({
          offerItemsLoadStatus: "error",
        });
      }
    }
  };

export const loadSettings =
  (applicationCodeId) =>
  async ({ setState, getState }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.getAppSettings(applicationCodeId);
      setState({
        settings: res.data,
        settingsLoadStatus: "success",
      });
      const defaultView = res.data.find((s) => s.isDefault);
      if (defaultView) {
        setState({
          selectedViewId: defaultView.userAppSettingId,
        });
      }
    } catch (err) {
      handleAxiosError(err);
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const invalidateOffer =
  (payload, onSuccess?) =>
  async ({ setState, getState, dispatch }) => {
    if (getState().invalidateOfferStatus === "loading") return;
    try {
      setState({
        invalidateOfferStatus: "loading",
      });
      await API.invalidateOffer(payload);
      setState({
        invalidateOfferStatus: "success",
      });
      onSuccess && onSuccess();
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err);
      setState({
        invalidateOfferStatus: "error",
      });
    }
  };

export const submitInsurerReportForm =
  (data: any, t, onSuccess?) =>
  async ({ setState, getState }) => {
    setState({
      submitInsurerReportFormStatus: "loading",
    });
    const payload: any = {
      DateStart: data.DateStart
        ? moment(data.DateStart).format("YYYY-MM-DD")
        : null,
      DateEnd: data.DateEnd ? moment(data.DateEnd).format("YYYY-MM-DD") : null,
    };
    const response = await API.sendInsurerReportPolicies(payload);
    setState({
      submitInsurerReportFormStatus: "success",
    });
    onSuccess && onSuccess(response.data.Message);
    return response;
  };

export const createSetting =
  (newSetting: Setting) =>
  async ({ setState, getState }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.addAppSetting(newSetting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      const newSettingId = allSettings.find(
        (s) => s.userAppSettingName === newSetting.userAppSettingName
      )?.userAppSettingId;
      if (newSettingId) {
        setState({
          selectedViewId: newSettingId,
        });
      }
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err);
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const deleteSetting =
  (id: number) =>
  async ({ setState, getState }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.deleteAppSetting(id);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err);
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

export const updateSetting =
  (setting: Setting) =>
  async ({ setState, getState }) => {
    if (getState().settingsLoadStatus === "loading") return;
    try {
      setState({
        settingsLoadStatus: "loading",
      });
      const res = await API.updateAppSetting(setting);
      const allSettings = res.data;
      setState({
        settings: allSettings,
        settingsLoadStatus: "success",
      });
      // toast.success(i18next.t("greco.success"));
    } catch (err) {
      handleAxiosError(err);
      setState({
        settingsLoadStatus: "error",
      });
    }
  };

const hydrateOffers = (newOffers: any) => {
  const columns = newOffers.map((row) => ({
    ...row,
    inp_lessee_username:
      row.inp_lessee_type === "Persoana fizica"
        ? row.inp_lessee_firstname + " " + row.inp_lessee_lastname
        : row.inp_lessee_company_name,
  }));
  return columns;
};
