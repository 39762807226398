import { useTheme } from "@emotion/react";
import { Spinner } from "@fluentui/react-components";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useAppStoreActions, useAppStoreState } from "store/AppStore";
import { useFormPageStore } from "store/FormPageStore";
import { resolverFn } from "utils/setYupLocale";
import { fieldToTab, getClasses } from "utils/utils";
import * as yup from "yup";
import { createCertificateSchema } from "../useFormValidationSchema";

import {
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  MenuPopover,
  MenuTrigger,
} from "@fluentui/react-components";
import { toast } from "components/FluentToast";

import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  Link,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { CertificateRegular } from "@fluentui/react-icons";
import { routes, useCurrentRoute } from "config/routes";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import { MessageBarType } from "office-ui-fabric-react";
import { random } from "lodash";
import {
  DocumentMultipleRegular,
  DocumentPdfRegular,
} from "@fluentui/react-icons";
import { HorizontalSpacer } from "components/styled";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr) auto",
    gridGap: "10px",
  },
  gridPremium: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "0px 10px",
  },
});

const ReviewSection = (props: Props) => {
  const isLoading = props.isLoading;
  const { setValue, getValues, setError, clearErrors } = props.form;
  const { t } = useTranslation();

  const [
    { visibleMap, disabledMap, requiredMap },
    { calculate, createCertificate },
  ] = useFormPageStore();
  const styles = useStyles();
  const navigate = useNavigate();

  const formPageStore = useFormPageStore();
  const { taxonomy } = useAppStoreState();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const [
    { accordionCollapsedMap, createCertificateStatus },
    { setFormStoreValue },
  ] = formPageStore;
  const route = useCurrentRoute();

  const theme = useTheme();
  const isDarkMode = theme.isDark;

  const classes = getClasses(theme, isDarkMode);

  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };

  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  return (
    <>
      <Card
        className={styles.card}
        style={{
          padding: "30px",
          borderTop:
            "5px solid " +
            (getValues("InvalidatedByUserFullName")
              ? theme.palette.red
              : theme.palette.themePrimary),
        }}
      >
        <CardHeader
          header={
            <Body1>{/* <b>{t("ehm.calculationSection.label")}</b> */}</Body1>
          }
        />
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>
            {t("ehm.NetPurchasingValueOfVehicle.label")}
          </div>
          <div
            style={{
              fontWeight: "600",
              fontSize: "1em",
              marginTop: "6px",
              ...fieldStyle(1, 2),
            }}
          >
            {getValues("NetPurchasingValueOfVehicle")} Ft
          </div>
          {route === "formPageNewOffer" ? (
            <div style={labelStyle(1, 3)}>
              <Link
                onClick={() => setFormStoreValue("selectedTab", "tab1")}
                style={{
                  visibility:
                    createCertificateStatus === "loading"
                      ? "hidden"
                      : "visible",
                }}
              >
                {t("ehm.edit.label")}
              </Link>
            </div>
          ) : null}
          <div style={labelStyle(2, 1)}>{t("ehm.Deductible.label")}</div>
          <div
            style={{
              fontWeight: "600",
              fontSize: "1em",
              marginTop: "6px",
              ...fieldStyle(2, 2),
            }}
          >
            {t(
              taxonomy["EHMDeductibleGeneral"].items?.find(
                (el) => el.id + "" === getValues("DeductibleGeneral") + ""
              )?.code
            )}{" "}
            ({t("ehm.general.label")})
            <br />
            {t(
              taxonomy["EHMDeductibleBrokenGlass"].items?.find(
                (el) => el.id + "" === getValues("DeductibleBrokenGlass") + ""
              )?.code
            )}{" "}
            ({t("ehm.brokenGlass.label")})
          </div>
          {route === "formPageNewOffer" ? (
            <div style={labelStyle(2, 3)}>
              <Link
                onClick={() => setFormStoreValue("selectedTab", "tab1")}
                style={{
                  visibility:
                    createCertificateStatus === "loading"
                      ? "hidden"
                      : "visible",
                }}
              >
                {t("ehm.edit.label")}
              </Link>
            </div>
          ) : null}
          <div style={labelStyle(3, 1)}>{t("ehm.PolicyPremium.label")}</div>
          <div
            style={{
              fontWeight: "600",
              fontSize: "1em",
              marginTop: "6px",
              ...fieldStyle(3, 2),
            }}
          >
            {getValues("PolicyPremium")} Ft ({getValues("InsurerName")})
          </div>
          {route === "formPageNewOffer" ? (
            <div style={labelStyle(3, 3)}>
              <Link
                onClick={() => setFormStoreValue("selectedTab", "tab1")}
                style={{
                  visibility:
                    createCertificateStatus === "loading"
                      ? "hidden"
                      : "visible",
                }}
              >
                {t("ehm.edit.label")}
              </Link>
            </div>
          ) : null}
          <div style={labelStyle(4, 1)}>{t("ehm.object.label")}</div>
          <div
            style={{
              fontWeight: "600",
              fontSize: "1em",
              marginTop: "6px",
              ...fieldStyle(4, 2),
            }}
          >
            {getValues("VehicleName")}
            <br />
            {getValues("VehicleVin")}
            <br />
            {getValues("VehicleBrand")} {getValues("VehicleModel")} <br />
            {moment(getValues("VehicleManufacturedOn")).format("YYYY.MM.DD")}
            <br />
            {getValues("IsNewVehicle") === true ||
            getValues("IsNewVehicle") === "true"
              ? "New"
              : "Used"}
            ,{" "}
            {getValues("IsElectricalVehicle") === true ||
            getValues("IsElectricalVehicle") === "true"
              ? "Electrical"
              : "Combustion"}
          </div>
          {route === "formPageNewOffer" ? (
            <div style={labelStyle(4, 3)}>
              <Link
                onClick={() => setFormStoreValue("selectedTab", "tab2")}
                style={{
                  visibility:
                    createCertificateStatus === "loading"
                      ? "hidden"
                      : "visible",
                }}
              >
                {t("ehm.edit.label")}
              </Link>
            </div>
          ) : null}
          <div style={labelStyle(5, 1)}>{t("ehm.company.label")}</div>
          <div
            style={{
              fontWeight: "600",
              fontSize: "1em",
              marginTop: "6px",
              ...fieldStyle(5, 2),
            }}
          >
            {getValues("CompanyName")}
            <br />
            {getValues("CompanyUid")}
            {"/"}
            {getValues("CompanyRegisterNumber")}
            <br />
            {getValues("CompanyStreet")} {getValues("CompanyHouseNumber")}{" "}
            {getValues("CompanyFlatNumber")}
            <br />
            {getValues("CompanyPostalCode")} {getValues("CompanyCity")}
            {getValues("CompanyTelephone") ? <br /> : null}
            {getValues("CompanyTelephone")}
            {getValues("CompanyEmail") ? <br /> : null}
            {getValues("CompanyEmail")}
          </div>
          {route === "formPageNewOffer" ? (
            <div style={labelStyle(5, 3)}>
              <Link
                onClick={() => setFormStoreValue("selectedTab", "tab3")}
                style={{
                  visibility:
                    createCertificateStatus === "loading"
                      ? "hidden"
                      : "visible",
                }}
              >
                {t("ehm.edit.label")}
              </Link>
            </div>
          ) : null}
          <div style={labelStyle(6, 1)}>{t("ehm.policy.label")}</div>
          <div
            style={{
              fontWeight: "600",
              fontSize: "1em",
              marginTop: "6px",
              ...fieldStyle(6, 2),
            }}
          >
            {getValues("PolicyNumberErsteFrameContract")}
            {" ("}
            {moment(getValues("PolicyStartDate")).format("YYYY.MM.DD")}
            {" - "}
            {moment(getValues("PolicyEndDate")).isValid()
              ? moment(getValues("PolicyEndDate")).format("YYYY.MM.DD")
              : t("ehm.indefinite.label")}
            {")"}
            <br />
            {t(
              taxonomy["EHMTerritoryOfCoverage"].items?.find(
                (el) => el.id + "" === getValues("TerritoryOfCoverage") + ""
              )?.code
            )}
            <br />
            {getValues("LeasingContractNumber")}
            {" ("}
            {moment(getValues("LeasingStartDate")).format("YYYY.MM.DD")}
            {" - "}
            {moment(getValues("LeasingEndDate")).format("YYYY.MM.DD")}
            {")"}
            <br />
          </div>
          {route === "formPageNewOffer" ? (
            <div style={labelStyle(6, 3)}>
              <Link
                onClick={() => setFormStoreValue("selectedTab", "tab4")}
                style={{
                  visibility:
                    createCertificateStatus === "loading"
                      ? "hidden"
                      : "visible",
                }}
              >
                {t("ehm.edit.label")}
              </Link>
            </div>
          ) : null}
        </div>

        {route === "formPage" ? (
          <>
            <div
              className={styles.gridPremium}
              style={{
                padding: "24px 0px 30px",
                backgroundColor: theme.palette.neutralLighter,
              }}
            >
              <div style={labelStyle(1, 1)}>{t("ehm.CertificateId.label")}</div>

              <div
                style={{
                  fontWeight: "600",
                  fontSize: "1em",
                  marginTop: "6px",
                  ...fieldStyle(1, 2),
                }}
              >
                {getValues("OfferId")}
              </div>

              <div style={labelStyle(2, 1)}>
                {t("ehm.OfferRequestedByUserFullName.label")}
              </div>

              <div
                style={{
                  fontWeight: "600",
                  fontSize: "1em",
                  marginTop: "6px",
                  ...fieldStyle(2, 2),
                }}
              >
                {getValues("OfferRequestedByUserFullName")} <br />
                {moment(getValues("OfferRequestedAt")).format(
                  "YYYY.MM.DD HH:mm"
                )}
              </div>

              {getValues("InvalidatedByUserFullName") !== null ? (
                <>
                  <div
                    style={{
                      color: theme.palette.red,
                      ...labelStyle(3, 1),
                    }}
                  >
                    {t("ehm.InvalidatedByUserFullName.label")}
                  </div>

                  <div
                    style={{
                      fontWeight: "600",
                      fontSize: "1em",
                      marginTop: "6px",
                      color: theme.palette.red,
                      ...fieldStyle(3, 2),
                    }}
                  >
                    {getValues("InvalidatedByUserFullName")} <br />
                    {moment(getValues("InvalidatedAt")).format(
                      "YYYY.MM.DD HH:mm"
                    )}
                  </div>
                </>
              ) : null}
            </div>
          </>
        ) : null}

        <CardFooter style={{ flexDirection: "row-reverse", marginTop: "20px" }}>
          {route === "formPageNewOffer" ? (
            <Button
              disabled={isLoading || createCertificateStatus === "loading"}
              appearance="primary"
              icon={
                createCertificateStatus === "loading" ? (
                  <Spinner size="tiny" />
                ) : (
                  <CertificateRegular fontSize={16} />
                )
              }
              onClick={() => {
                clearErrors();
                clearAllNotifications();

                resolverFn(
                  createCertificateSchema(yup, t),
                  props.form.getValues()
                ).then((res) => {
                  if (Object.keys(res.errors).length > 0) {
                    Object.keys(res.errors).forEach((key) => {
                      setError(key, res.errors[key]);
                      setNotificationMessage({
                        errors: [],
                        key: key,
                        messageBarType: MessageBarType.error,
                        fieldName: t("ehm." + key + ".label"),
                        handleNotificationClick: (e) => {
                          setFormStoreValue("selectedTab", fieldToTab(key));
                        },
                        notify: {
                          label: "label",
                          type: "error",
                          notifyCode: "notifyCode",
                          notifyText: (
                            <>
                              <b>{t("ehm." + key + ".label")}</b>
                              <br />
                              {res.errors[key].message}
                            </>
                          ),
                        },
                      });
                    });
                  } else {
                    // if passes, data is valid
                    createCertificate(
                      {
                        AdditionalCalculationInfo: {
                          NetPurchasingValueOfVehicle: parseInt(
                            getValues("NetPurchasingValueOfVehicle")
                              .replaceAll(/\s/g, "")
                              .replaceAll(",", "."),
                            10
                          ),
                          DeductibleGeneral: getValues("DeductibleGeneral"),
                          DeductibleBrokenGlass: getValues(
                            "DeductibleBrokenGlass"
                          ),
                        },

                        VehicleInfo: {
                          VehicleVin: getValues("VehicleVin"),
                          VehicleName: getValues("VehicleName"),
                          VehicleManufacturedOn: moment(
                            getValues("VehicleManufacturedOn")
                          ).format("YYYY-MM-DD"),
                          VehicleBrand: getValues("VehicleBrand"),
                          VehicleModel: getValues("VehicleModel"),
                          IsNewVehicle: getValues("IsNewVehicle"),
                          IsElectricalVehicle: getValues("IsElectricalVehicle"),
                        },
                        CompanyInfo: {
                          CompanyUid: getValues("CompanyUid"),
                          CompanyRegisterNumber: getValues(
                            "CompanyRegisterNumber"
                          ),
                          CompanyName: getValues("CompanyName"),
                          CompanyStreet: getValues("CompanyStreet"),
                          CompanyHouseNumber: getValues("CompanyHouseNumber"),
                          CompanyFlatNumber: getValues("CompanyFlatNumber"),
                          CompanyTelephone: getValues("CompanyTelephone"),
                          CompanyEmail: getValues("CompanyEmail"),
                          CompanyCity: getValues("CompanyCity"),
                          CompanyPostalCode: getValues("CompanyPostalCode"),
                        },
                        AdditionalPolicyInfo: {
                          PolicyNumberErsteFrameContract: getValues(
                            "PolicyNumberErsteFrameContract"
                          ),
                          PolicyStartDate: moment(
                            getValues("PolicyStartDate")
                          ).format("YYYY-MM-DD"),
                          PolicyEndDate: moment(
                            getValues("PolicyEndDate")
                          ).isValid()
                            ? moment(getValues("PolicyEndDate")).format(
                                "YYYY-MM-DD"
                              )
                            : null,
                          LeasingStartDate: moment(
                            getValues("LeasingStartDate")
                          ).format("YYYY-MM-DD"),
                          LeasingEndDate: moment(
                            getValues("LeasingEndDate")
                          ).format("YYYY-MM-DD"),
                          LeasingContractNumber: getValues(
                            "LeasingContractNumber"
                          ),
                          TerritoryOfCoverage: getValues("TerritoryOfCoverage"),
                        },
                      },
                      (retVal) => {
                        toast.success(t("greco.success"));
                        navigate(routes.formPage.getPath(retVal.OfferId));
                      },
                      (error) => {
                        setNotificationMessage({
                          errors: [],
                          key: random() + "createCertificate",
                          messageBarType: MessageBarType.error,
                          fieldName: t("ehm.createCertificate.label"),
                          handleNotificationClick: (e) => {
                            setFormStoreValue("selectedTab", "tab5");
                          },
                          notify: {
                            label: "label",
                            type: "error",
                            notifyCode: "notifyCode",
                            notifyText: (
                              <>
                                <b>{error}</b>
                              </>
                            ),
                          },
                        });
                      }
                    );
                  }
                });
              }}
            >
              {t("ehm.createCertificate.label")}
            </Button>
          ) : null}
          {route === "formPage" &&
          getValues("InvalidatedByUserFullName") === null ? (
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "repeat(3, 1fr) auto",
                gridGap: "10px",
                width: "100%",
              }}
            >
              <div
                style={{
                  gridRow: `1 / 1`,
                  gridColumn: `1 / 3`,
                  textAlign: "left",
                }}
              >
                <Button
                  disabled={isLoading}
                  appearance="secondary"
                  icon={<CertificateRegular fontSize={16} />}
                  onClick={() => {
                    window.open(
                      getValues("PathToAccessStatementPdf"),
                      "_blank"
                    );
                  }}
                >
                  {t("ehm.accessStatement.label")}
                </Button>
              </div>
              <div
                style={{
                  gridRow: `1 / 1`,
                  gridColumn: `3 / 3`,
                  textAlign: "right",
                }}
              >
                <Button
                  disabled={isLoading}
                  appearance="primary"
                  icon={<CertificateRegular fontSize={16} />}
                  onClick={() => {
                    window.open(getValues("PathToOfferPdf"), "_blank");
                  }}
                >
                  {t("ehm.downloadCertificate.label")}
                </Button>
              </div>
            </div>
          ) : null}
        </CardFooter>
      </Card>
    </>
  );
};

export default ReviewSection;
