import { Setting } from "@greco/components/dist/types/types";
import {
  createStore,
  createHook,
  createActionsHook,
  createStateHook,
} from "react-sweet-state";
import { ExpandedColumn } from "types/columns";
import { LoadStatus } from "types/types";
import { columns } from "../pages/ListPageNew/columns";
import {
  createSetting,
  deleteSetting,
  invalidateOffer,
  loadSettings,
  loadOffers,
  setStoreValue,
  updateSetting,
  loadOffersForReport,
  submitInsurerReportForm,
} from "./ListPageStoreActions";

export type StoreState = {
  settings: Setting[];
  settingsLoadStatus: LoadStatus;
  searchTerm: string;
  showInvalidated: boolean;
  vehicleItems: any[];
  xlsVehicleItems: any[];
  offerItemsLoadStatus: LoadStatus;
  submitInsurerReportFormStatus: LoadStatus;

  sort?: {
    key: string;
    dir: string;
  };
  skip: number;
  top?: number;
  cols: ExpandedColumn[];
  selectedViewColumns: ExpandedColumn[];
  selectedViewId: number;
  selectedView: any;
  tempColumns: ExpandedColumn[];
  count: number;
  invalidateOfferDialog: {
    isOpen: boolean;
    offer: any;
  };
  insurerReportDialog: {
    isOpen: boolean;
    formData: any;
  };
  invalidateOfferStatus: LoadStatus;
  insurerReportStatus: LoadStatus;
  areAnyColumnsFiltered: boolean;
  columnOptionsSettings: Setting[];
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  settings: [],
  settingsLoadStatus: "none",
  searchTerm: "",
  vehicleItems: [],
  xlsVehicleItems: [],
  offerItemsLoadStatus: "none",
  submitInsurerReportFormStatus: "none",

  sort: {
    key: "",
    dir: "",
  },
  showInvalidated: false,
  skip: 0,
  top: 40,
  cols: columns,
  selectedViewColumns: null,
  selectedViewId: null,
  selectedView: null,
  tempColumns: columns,
  count: 0,
  invalidateOfferDialog: {
    isOpen: false,
    offer: null,
  },
  insurerReportDialog: {
    isOpen: false,
    formData: null,
  },
  invalidateOfferStatus: "none",
  insurerReportStatus: "none",
  areAnyColumnsFiltered: false,
  columnOptionsSettings: [],
};

// All the actions that mutate the store
const actions = {
  setListStoreValue: setStoreValue,
  loadSettings: loadSettings,
  loadOffers: loadOffers,
  loadOffersForReport: loadOffersForReport,
  createSetting: createSetting,
  deleteSetting: deleteSetting,
  updateSetting: updateSetting,
  invalidateOffer: invalidateOffer,
  submitInsurerReportForm: submitInsurerReportForm,
};

const ListPageStore = createStore({
  initialState,
  actions,
  name: "ListPageStore",
});
export const useListPageStore = createHook(ListPageStore);
export const useListPageStoreState = createStateHook(ListPageStore);
export const useListPageStoreActions = createActionsHook(ListPageStore);
