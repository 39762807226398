import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useListPageStore } from "store/ListPageStore";
import {
  Button,
  Dialog,
  DialogActions,
  DialogBody,
  DialogContent,
  DialogSurface,
  DialogTitle,
  Spinner,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import DatePickerField from "components/controls9/DatePickerField";
import { resolverFn, useYupValidationResolver } from "utils/setYupLocale";
import {
  insurerReportSchema,
  useFormValidationSchema,
} from "pages/FormPageNew/useFormValidationSchema";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useTheme } from "@fluentui/react";
import { DocumentBulletListMultipleRegular } from "@fluentui/react-icons";
import { useAppStoreActions } from "store/AppStore";
import { MessageBarType } from "office-ui-fabric-react";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  gridPremium: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

export const InsurerReportDialog = () => {
  const [
    { insurerReportDialog, submitInsurerReportFormStatus },
    { setListStoreValue, submitInsurerReportForm },
  ] = useListPageStore();
  const styles = useStyles();
  const theme = useTheme();

  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const { t } = useTranslation();
  const [message, setMessage] = React.useState<string>("");
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  const schema = useFormValidationSchema(yup, t);

  const resolver = useYupValidationResolver(schema);

  const form = useForm({
    defaultValues: {
      DateStart: null,
      DateEnd: null,
    },
    values: {
      DateStart: null,
      DateEnd: null,
    },
    resolver,
  });
  const { setError, setValue } = form;

  useEffect(() => {
    form.reset({
      DateStart: null,
      DateEnd: null,
    });
    setMessage("");
  }, [insurerReportDialog.formData]);

  const isLoading = submitInsurerReportFormStatus === "loading";
  return (
    <Dialog open={insurerReportDialog.isOpen}>
      <DialogSurface>
        <DialogBody>
          <DialogTitle>{t("ehm.ExportInsurerReport.label")}</DialogTitle>
          <DialogContent>
            <div className={styles.grid}>
              {message ? null : (
                <>
                  <div style={labelStyle(1, 1)}>
                    {t("ehm.DateStart.label")}*
                  </div>
                  <div style={fieldStyle(1, 2)}>
                    <DatePickerField
                      isLoading={submitInsurerReportFormStatus === "loading"}
                      form={form}
                      name="DateStart"
                      label={"ehm.DateStart.label"}
                      data-format="YYYY.MM.DD"
                      data-dateFormat="YYYY.MM.DD"
                      required={true}
                      disabled={false}
                      visible={true}
                    />
                  </div>
                  <div style={labelStyle(2, 1)}>{t("ehm.DateEnd.label")}*</div>
                  <div style={fieldStyle(2, 2)}>
                    <DatePickerField
                      isLoading={submitInsurerReportFormStatus === "loading"}
                      form={form}
                      name="DateEnd"
                      label={"ehm.DateEnd.label"}
                      data-format="YYYY.MM.DD"
                      data-dateFormat="YYYY.MM.DD"
                      required={true}
                      disabled={false}
                      visible={true}
                    />
                  </div>
                </>
              )}
              <div
                style={{
                  ...labelStyle(3, 1),
                  gridColumn: `1 / 3`,
                  margin: "20px",
                  textAlign: "left",
                  color: theme.palette.neutralPrimary,
                }}
              >
                {message}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button
              appearance="secondary"
              disabled={isLoading}
              onClick={() => {
                setListStoreValue("insurerReportDialog", {
                  isOpen: false,
                  formData: null,
                });
              }}
            >
              {t("ehm.close.label")}
            </Button>
            {message ? null : (
              <Button
                appearance="primary"
                disabled={isLoading}
                icon={
                  submitInsurerReportFormStatus === "loading" ? (
                    <Spinner size="tiny" />
                  ) : (
                    <DocumentBulletListMultipleRegular fontSize={16} />
                  )
                }
                onClick={() => {
                  form.clearErrors();
                  clearAllNotifications();

                  setMessage("");
                  resolverFn(
                    insurerReportSchema(yup, t),
                    form.getValues()
                  ).then((res) => {
                    if (Object.keys(res.errors).length > 0) {
                      Object.keys(res.errors).forEach((key) => {
                        setError(key, res.errors[key]);
                        // setNotificationMessage({
                        //   errors: [],
                        //   key: key,
                        //   messageBarType: MessageBarType.error,
                        //   fieldName: t("ehm." + key + ".label"),
                        //   handleNotificationClick: (e) => {
                        //     // setFormStoreValue("selectedTab", "tab2");
                        //   },
                        //   notify: {
                        //     label: "label",
                        //     type: "error",
                        //     notifyCode: "notifyCode",
                        //     notifyText: (
                        //       <>
                        //         <b>{t("ehm." + key + ".label")}</b>
                        //         <br />
                        //         {res.errors[key].message}
                        //       </>
                        //     ),
                        //   },
                        // });
                      });
                    } else {
                      submitInsurerReportForm(
                        form.getValues(),
                        t,
                        (responseMessage) => {
                          setMessage(responseMessage);
                        }
                      );
                    }
                  });
                }}
              >
                {t("ehm.Export.label")}
              </Button>
            )}
          </DialogActions>
        </DialogBody>
      </DialogSurface>
    </Dialog>
  );
};
