import React, { useEffect } from "react";
import { useTheme } from "@emotion/react";
import { useTranslation } from "react-i18next";
import { useFormPageStore } from "store/FormPageStore";
import { useYupValidationResolver } from "utils/setYupLocale";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { useFormValidationSchema } from "./useFormValidationSchema";
import { Flex, FlexRight, NavigationBar } from "components/styled";
import { routes, useCurrentRoute } from "config/routes";
import { useNavigate, useParams } from "react-router-dom";
import CalculationSection from "./sections/CalculationSection";
import CompanySection from "./sections/CompanySection";
import VehicleSection from "./sections/VehicleSection";
import PolicySection from "./sections/PolicySection";
import {
  initialDisabledMap,
  initialOffer,
  initialRequiredMap,
  initialVisibleMap,
} from "store/FormPageStoreInitialValues";
import { useAppStore, useAppStoreState } from "store/AppStore";
import { ThemeProvider } from "@greco/components";
import { ArrowLeftFilled } from "@fluentui/react-icons";

import {
  Button,
  Tab,
  TabList,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import {
  MoneyRegular,
  DocumentRibbonRegular,
  VehicleSubwayRegular,
  BuildingRegular,
  CalendarLtrRegular,
  CertificateRegular,
} from "@fluentui/react-icons";
import ReviewSection from "./sections/ReviewSection";
import SpinnerCard from "./SpinnerCard";
import { CommandBarButton } from "@fluentui/react";

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    justifyContent: "center",
  },
});

const FormPage = () => {
  const { t } = useTranslation();
  const [
    {
      offer,
      repeatOffer,
      visibleMap,
      offerLoadStatus,
      selectedTab,
      disabledTab1,
      disabledTab2,
      disabledTab3,
      disabledTab4,
      disabledTab5,
      createCertificateStatus,
    },
    { loadOffer, setFormStoreValue, setFormStoreValues, reset },
  ] = useFormPageStore();
  const [{ appState, taxonomy }, { clearAllNotifications }] = useAppStore();

  const hungarianTerritoryOfCoverage = taxonomy
    ? taxonomy["EHMTerritoryOfCoverage"]?.items.find(
        (el) => el.code === "EHMTerritoryOfCoverage.Hungary"
      )
    : null;

  const classes = useStyles();
  const { itemId } = useParams() as any;
  const route = useCurrentRoute();

  const navigate = useNavigate();
  const schema = useFormValidationSchema(yup, t);

  const resolver = useYupValidationResolver(schema);

  const isLoading = offerLoadStatus === "loading";

  const form = useForm({
    resolver,
    defaultValues: initialOffer,
    values: initialOffer,
  });

  const insuranceConditionsMenuProps = {
    items: [
      {
        key: "doc1",
        text: t("ehm.insuranceConditionsDoc1.label"),
        preferMenuTargetAsEventTarget: true,
        onClick: (
          ev?:
            | React.MouseEvent<HTMLElement, MouseEvent>
            | React.KeyboardEvent<HTMLElement>
            | undefined
        ) => {
          window.open(
            process.env.REACT_APP_GENERAL_INSURANCE_CONDITIONS_DOC_URL,
            "_blank"
          );
        },
      },
      {
        key: "doc2",
        text: t("ehm.insuranceConditionsDoc2.label"),
        preferMenuTargetAsEventTarget: true,
        onClick: (
          ev?:
            | React.MouseEvent<HTMLElement, MouseEvent>
            | React.KeyboardEvent<HTMLElement>
            | undefined
        ) => {
          window.open(
            process.env.REACT_APP_GENERAL_INSURANCE_CONDITIONS_DOC2_URL,
            "_blank"
          );
        },
      },
    ],
  };

  useEffect(() => {
    reset();
    return () => {
      reset();
      clearAllNotifications();
    };
  }, []);

  useEffect(() => {
    if (route === "formPage") {
      if (itemId && t && taxonomy) {
        loadOffer(itemId, t, taxonomy, (offer) => {
          setFormStoreValue("disabledMap", {
            NetPurchasingValueOfVehicle: true,
            DeductibleGeneral: true,
            DeductibleBrokenGlass: true,
            VehicleVin: true,
            VehicleName: true,
            VehicleManufacturedOn: true,
            IsNewVehicle: true,
            VehicleBrand: true,
            VehicleModel: true,
            IsElectricalVehicle: true,
            CompanyName: true,
            CompanyUid: true,
            CompanyRegisterNumber: true,
            CompanyStreet: true,
            CompanyHouseNumber: true,
            CompanyFlatNumber: true,
            CompanyPostalCode: true,
            CompanyCity: true,
            CompanyTelephone: true,
            CompanyEmail: true,
            PolicyNumberErsteFrameContract: true,
            PolicyStartDate: true,
            PolicyEndDate: true,
            LeasingStartDate: true,
            LeasingEndDate: true,
            LeasingContractNumber: true,
            TerritoryOfCoverage: true,
            calculate: true,
            createCertificate: true,
            downloadCertificate: offer.IsInvalidated,
            InsurerName: true,
            PolicyPremium: true,
            Status: true,
            PathToOfferPdf: false,
            PathToAccessStatementPdf: false,
            OfferRequestedAt: true,
          });
          setFormStoreValue("visibleMap", {
            ...visibleMap,
            calculate: false,
            createCertificate: false,
            downloadCertificate: !offer.IsInvalidated,

            InsurerName: true,
            PolicyPremium: true,
            Status: true,
            PathToOfferPdf: true,
            PathToAccessStatementPdf: true,
            OfferRequestedAt: true,
          });
        });
      }
    } else if (route === "formPageNewOffer" && taxonomy) {
      setFormStoreValue("disabledMap", initialDisabledMap);
      setFormStoreValue("visibleMap", initialVisibleMap);
      setFormStoreValue("requiredMap", initialRequiredMap);
    }
  }, [route, itemId, t, taxonomy]);

  useEffect(() => {
    if (route === "formPage") {
      form.reset(offer);
    } else if (route === "formPageNewOffer" && taxonomy) {
      if (repeatOffer) {
        form.reset(repeatOffer);
        setFormStoreValues({
          repeatOffer: null,
          selectedTab: "tab1",
          disabledTab1: false,
          disabledTab2: true,
          disabledTab3: true,
          disabledTab4: true,
          disabledTab5: true,
        });
      } else {
        form.reset(initialOffer);
        form.setValue(
          "DeductibleBrokenGlass",
          taxonomy["EHMDeductibleBrokenGlass"].items[0].id
        );
        form.setValue("TerritoryOfCoverage", hungarianTerritoryOfCoverage?.id);
      }
    }
  }, [offer, route, taxonomy]);

  return (
    <ThemeProvider isDarkMode={appState.darkMode}>
      <NavigationBar>
        <Flex direction="row" justifyContent="flex-start">
          <Button
            appearance="subtle"
            icon={<ArrowLeftFilled />}
            onClick={() => {
              navigate(routes.listPage.getPath());
            }}
          >
            {t("ehm.Back.label")}
          </Button>
        </Flex>
        <FlexRight>
          <Flex>
            <CommandBarButton
              onRenderIcon={() => <DocumentRibbonRegular fontSize={24} />}
              menuProps={insuranceConditionsMenuProps}
              style={{ marginRight: "10px", height: "42px" }}
              disabled={isLoading}
              text={t("ehm.insuranceConditions.label")}
            />
          </Flex>
        </FlexRight>
      </NavigationBar>

      {route === "formPageNewOffer" ? (
        <TabList selectedValue={selectedTab} className={classes.card}>
          <Tab
            icon={<MoneyRegular />}
            value="tab1"
            disabled={disabledTab1 || createCertificateStatus === "loading"}
            onClick={() => setFormStoreValue("selectedTab", "tab1")}
          >
            {t("ehm.calculationSection.label")}
          </Tab>
          <Tab
            icon={<VehicleSubwayRegular />}
            value="tab2"
            disabled={disabledTab2 || createCertificateStatus === "loading"}
            onClick={() => setFormStoreValue("selectedTab", "tab2")}
          >
            {t("ehm.vehicleSection.label")}
          </Tab>
          <Tab
            icon={<BuildingRegular />}
            value="tab3"
            disabled={disabledTab3 || createCertificateStatus === "loading"}
            onClick={() => setFormStoreValue("selectedTab", "tab3")}
          >
            {t("ehm.companySection.label")}
          </Tab>
          <Tab
            icon={<CalendarLtrRegular />}
            value="tab4"
            disabled={disabledTab4 || createCertificateStatus === "loading"}
            onClick={() => setFormStoreValue("selectedTab", "tab4")}
          >
            {t("ehm.policySection.label")}
          </Tab>
          <Tab
            icon={<CertificateRegular />}
            value="tab5"
            disabled={disabledTab5}
            onClick={() => setFormStoreValue("selectedTab", "tab5")}
          >
            {t("ehm.createCertificateSection.label")}
          </Tab>
        </TabList>
      ) : !taxonomy || isLoading ? (
        <SpinnerCard />
      ) : (
        <ReviewSection form={form} isLoading={isLoading} />
      )}

      {route === "formPageNewOffer" ? (
        <>
          {selectedTab === "tab1" ? (
            <CalculationSection form={form} isLoading={isLoading} />
          ) : null}
          {selectedTab === "tab2" ? (
            <VehicleSection form={form} isLoading={isLoading} />
          ) : null}
          {selectedTab === "tab3" ? (
            <CompanySection form={form} isLoading={isLoading} />
          ) : null}
          {selectedTab === "tab4" ? (
            <PolicySection form={form} isLoading={isLoading} />
          ) : null}
          {selectedTab === "tab5" ? (
            <ReviewSection form={form} isLoading={isLoading} />
          ) : null}
        </>
      ) : null}
    </ThemeProvider>
  );
};

export default FormPage;
