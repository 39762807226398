import { useTheme } from "@emotion/react";
import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { CalendarLtrRegular } from "@fluentui/react-icons";
import TextInputField from "components/controls9/TextInputField";
import { MessageBarType } from "office-ui-fabric-react";
import React from "react";
import { UseFormReturn } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { useFormPageStore, useFormPageStoreState } from "store/FormPageStore";
import { resolverFn } from "utils/setYupLocale";
import { fieldToTab, getClasses } from "utils/utils";
import * as yup from "yup";
import { companySchema } from "../useFormValidationSchema";
import { useAppStoreActions } from "store/AppStore";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const CompanySection = (props: Props) => {
  const { t } = useTranslation();
  const { setValue, getValues, setError, clearErrors } = props.form;

  const formPageStore = useFormPageStore();
  const isLoading = props.isLoading;
  const styles = useStyles();

  const [{ accordionCollapsedMap }, { setFormStoreValue }] = formPageStore;
  const { visibleMap, disabledMap, requiredMap } = useFormPageStoreState();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const theme = useTheme();
  const isDarkMode = theme.isDark;
  const classes = getClasses(theme, isDarkMode);
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  return (
    <>
      <Card
        className={styles.card}
        style={{
          padding: "30px",
          borderTop:
            "5px solid " +
            (getValues("InvalidatedByUserFullName")
              ? theme.palette.red
              : theme.palette.themePrimary),
        }}
      >
        <CardHeader
          header={
            <Body1>{/* <b>{t("ehm.calculationSection.label")}</b> */}</Body1>
          }
        />
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>{t("ehm.CompanyName.label")}*</div>
          <div style={fieldStyle(1, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyName"
              label={"ehm.CompanyName.label"}
              required={requiredMap.CompanyName}
              disabled={disabledMap.CompanyName}
              visible={visibleMap.CompanyName}
            />
          </div>
          <div style={labelStyle(2, 1)}>{t("ehm.CompanyUid.label")}*</div>
          <div style={fieldStyle(2, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyUid"
              label={"ehm.CompanyUid.label"}
              required={requiredMap.CompanyUid}
              disabled={disabledMap.CompanyUid}
              visible={visibleMap.CompanyUid}
            />
          </div>
          <div style={labelStyle(3, 1)}>
            {t("ehm.CompanyRegisterNumber.label")}*
          </div>
          <div style={fieldStyle(3, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyRegisterNumber"
              label={"ehm.CompanyRegisterNumber.label"}
              required={requiredMap.CompanyRegisterNumber}
              disabled={disabledMap.CompanyRegisterNumber}
              visible={visibleMap.CompanyRegisterNumber}
            />
          </div>
          <div style={labelStyle(4, 1)}>{t("ehm.CompanyStreet.label")}*</div>
          <div style={fieldStyle(4, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyStreet"
              label={"ehm.CompanyStreet.label"}
              required={requiredMap.CompanyStreet}
              disabled={disabledMap.CompanyStreet}
              visible={visibleMap.CompanyStreet}
            />
          </div>
          <div style={labelStyle(5, 1)}>
            {t("ehm.CompanyHouseNumber.label")}*
          </div>
          <div style={fieldStyle(5, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyHouseNumber"
              label={"ehm.CompanyHouseNumber.label"}
              required={requiredMap.CompanyHouseNumber}
              disabled={disabledMap.CompanyHouseNumber}
              visible={visibleMap.CompanyHouseNumber}
              width="80px"
            />
          </div>
          <div style={labelStyle(6, 1)}>{t("ehm.CompanyFlatNumber.label")}</div>
          <div style={fieldStyle(6, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyFlatNumber"
              label={"ehm.CompanyFlatNumber.label"}
              required={requiredMap.CompanyFlatNumber}
              disabled={disabledMap.CompanyFlatNumber}
              visible={visibleMap.CompanyFlatNumber}
              width="80px"
            />
          </div>
          <div style={labelStyle(7, 1)}>
            {t("ehm.CompanyPostalCode.label")}*
          </div>
          <div style={fieldStyle(7, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyPostalCode"
              label={"ehm.CompanyPostalCode.label"}
              required={requiredMap.CompanyPostalCode}
              disabled={disabledMap.CompanyPostalCode}
              visible={visibleMap.CompanyPostalCode}
              width="160px"
            />
          </div>
          <div style={labelStyle(8, 1)}>{t("ehm.CompanyCity.label")}*</div>
          <div style={fieldStyle(8, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyCity"
              label={"ehm.CompanyCity.label"}
              required={requiredMap.CompanyCity}
              disabled={disabledMap.CompanyCity}
              visible={visibleMap.CompanyCity}
            />
          </div>
          <div style={labelStyle(9, 1)}>{t("ehm.CompanyTelephone.label")}</div>
          <div style={fieldStyle(9, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyTelephone"
              label={"ehm.CompanyTelephone.label"}
              required={requiredMap.CompanyTelephone}
              disabled={disabledMap.CompanyTelephone}
              visible={visibleMap.CompanyTelephone}
              width="160px"
            />
          </div>
          <div style={labelStyle(10, 1)}>{t("ehm.CompanyEmail.label")}</div>
          <div style={fieldStyle(10, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="CompanyEmail"
              label={"ehm.CompanyEmail.label"}
              required={requiredMap.CompanyEmail}
              disabled={disabledMap.CompanyEmail}
              visible={visibleMap.CompanyEmail}
            />
          </div>
        </div>
        <CardFooter style={{ flexDirection: "row-reverse", marginTop: "20px" }}>
          <Button
            appearance="subtle"
            icon={<CalendarLtrRegular fontSize={16} />}
            onClick={() => {
              clearErrors();
              clearAllNotifications();
              resolverFn(companySchema(yup, t), getValues()).then((res) => {
                if (Object.keys(res.errors).length > 0) {
                  Object.keys(res.errors).forEach((key) => {
                    setError(key, res.errors[key]);
                    setNotificationMessage({
                      errors: [],
                      key: key,
                      messageBarType: MessageBarType.error,
                      fieldName: t("ehm." + key + ".label"),
                      handleNotificationClick: (e) => {
                        setFormStoreValue("selectedTab", fieldToTab(key));
                      },
                      notify: {
                        label: "label",
                        type: "error",
                        notifyCode: "notifyCode",
                        notifyText: (
                          <>
                            <b>{t("ehm." + key + ".label")}</b>
                            <br />
                            {res.errors[key].message}
                          </>
                        ),
                      },
                    });
                  });
                } else {
                  setFormStoreValue("disabledTab4", false);
                  setFormStoreValue("selectedTab", "tab4");
                }
              });
            }}
          >
            {t("ehm.EnterPolicyInfo.label")}
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default CompanySection;
