import React, { useRef } from "react";
import Accordion from "components/accordion/Accordion";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { useFormPageStore, useFormPageStoreState } from "store/FormPageStore";
import { useTheme } from "@emotion/react";
import TextInputField from "components/controls9/TextInputField";
import DatePickerField from "components/controls9/DatePickerField";
import CheckboxField from "components/controls9/CheckboxField";
import { fieldToTab, getClasses } from "utils/utils";
import { MessageBarType, Nav } from "office-ui-fabric-react";
import {
  Body1,
  Button,
  Caption1,
  Card,
  CardFooter,
  CardHeader,
  CardPreview,
  GriffelStyle,
  Input,
  MenuButton,
  Select,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import NumberInputField from "components/controls9/NumberInputField";
import { BuildingRegular } from "@fluentui/react-icons";
import { resolverFn } from "utils/setYupLocale";
import * as yup from "yup";
import { vehicleSchema } from "../useFormValidationSchema";
import { useAppStoreActions } from "store/AppStore";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const VehicleSection = (props: Props) => {
  const isLoading = props.isLoading;
  const { t } = useTranslation();
  const { setValue, getValues, setError, clearErrors } = props.form;

  const styles = useStyles();
  const formPageStore = useFormPageStore();
  const { visibleMap, disabledMap, requiredMap } = useFormPageStoreState();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();

  const [{ accordionCollapsedMap }, { setFormStoreValue }] = formPageStore;

  const theme = useTheme();
  const isDarkMode = theme.isDark;

  const classes = getClasses(theme, isDarkMode);
  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };
  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };
  return (
    <>
      <Card
        className={styles.card}
        style={{
          padding: "30px",
          borderTop:
            "5px solid " +
            (getValues("InvalidatedByUserFullName")
              ? theme.palette.red
              : theme.palette.themePrimary),
        }}
      >
        <CardHeader
          header={
            <Body1>{/* <b>{t("ehm.calculationSection.label")}</b> */}</Body1>
          }
        />
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>{t("ehm.VehicleName.label")}*</div>
          <div style={fieldStyle(1, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="VehicleName"
              label={"ehm.VehicleName.label"}
              required={requiredMap.VehicleName}
              disabled={disabledMap.VehicleName}
              visible={visibleMap.VehicleName}
            />
          </div>
          <div style={labelStyle(2, 1)}>{t("ehm.VehicleVin.label")}*</div>
          <div style={fieldStyle(2, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="VehicleVin"
              label={"ehm.VehicleVin.label"}
              required={requiredMap.VehicleBrand}
              disabled={disabledMap.VehicleBrand}
              visible={visibleMap.VehicleBrand}
              width="200px"
            />
          </div>
          <div style={labelStyle(3, 1)}>{t("ehm.VehicleBrand.label")}*</div>
          <div style={fieldStyle(3, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="VehicleBrand"
              label={"ehm.VehicleBrand.label"}
              required={requiredMap.VehicleBrand}
              disabled={disabledMap.VehicleBrand}
              visible={visibleMap.VehicleBrand}
            />
          </div>

          <div style={labelStyle(4, 1)}>{t("ehm.VehicleModel.label")}*</div>
          <div style={fieldStyle(4, 2)}>
            <TextInputField
              isLoading={isLoading}
              form={props.form}
              name="VehicleModel"
              label={"ehm.VehicleModel.label"}
              required={requiredMap.VehicleModel}
              disabled={disabledMap.VehicleModel}
              visible={visibleMap.VehicleModel}
            />
          </div>
          <div style={labelStyle(5, 1)}>
            {t("ehm.VehicleManufacturedOn.label")}*
          </div>
          <div style={fieldStyle(5, 2)}>
            <div style={{ display: "flex" }}>
              <DatePickerField
                isLoading={isLoading}
                form={props.form}
                name="VehicleManufacturedOn"
                label={"ehm.VehicleManufacturedOn.label"}
                data-dateFormat="YYYY.MM.DD"
                required={requiredMap.VehicleManufacturedOn}
                disabled={disabledMap.VehicleManufacturedOn}
                visible={visibleMap.VehicleManufacturedOn}
              />
            </div>
          </div>
          <div style={labelStyle(6, 1)}>{t("ehm.IsNewVehicle.label")}*</div>
          <div style={fieldStyle(6, 2)}>
            <div style={{ display: "flex" }}>
              <CheckboxField
                isLoading={isLoading}
                form={props.form}
                name="IsNewVehicle"
                label={"ehm.IsNewVehicle.label"}
                required={requiredMap.IsNewVehicle}
                disabled={disabledMap.IsNewVehicle}
                visible={visibleMap.IsNewVehicle}
              />
            </div>
          </div>
          <div style={labelStyle(7, 1)}>
            {t("ehm.IsElectricalVehicle.label")}*
          </div>
          <div style={fieldStyle(7, 2)}>
            <div style={{ display: "flex" }}>
              <CheckboxField
                isLoading={isLoading}
                form={props.form}
                name="IsElectricalVehicle"
                label={"ehm.IsElectricalVehicle.label"}
                required={requiredMap.IsElectricalVehicle}
                disabled={disabledMap.IsElectricalVehicle}
                visible={visibleMap.IsElectricalVehicle}
              />
            </div>
          </div>
        </div>
        <CardFooter style={{ flexDirection: "row-reverse", marginTop: "20px" }}>
          <Button
            appearance="subtle"
            icon={<BuildingRegular fontSize={16} />}
            onClick={async () => {
              clearErrors();
              clearAllNotifications();

              resolverFn(vehicleSchema(yup, t), getValues()).then((res) => {
                if (Object.keys(res.errors).length > 0) {
                  Object.keys(res.errors).forEach((key) => {
                    setError(key, res.errors[key]);
                    setNotificationMessage({
                      errors: [],
                      key: key,
                      messageBarType: MessageBarType.error,
                      fieldName: t("ehm." + key + ".label"),
                      handleNotificationClick: (e) => {
                        setFormStoreValue("selectedTab", fieldToTab(key));
                      },
                      notify: {
                        label: "label",
                        type: "error",
                        notifyCode: "notifyCode",
                        notifyText: (
                          <>
                            <b>{t("ehm." + key + ".label")}</b>
                            <br />
                            {res.errors[key].message}
                          </>
                        ),
                      },
                    });
                  });
                } else {
                  setFormStoreValue("disabledTab3", false);
                  setFormStoreValue("selectedTab", "tab3");
                }
              });
            }}
          >
            {t("ehm.EnterCompanyInfo.label")}
          </Button>
        </CardFooter>
      </Card>
    </>
  );
};

export default VehicleSection;
