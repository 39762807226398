import { flattenObject, handleAxiosError } from "utils/utils";
import * as API from "../api/api";
import { StoreState } from "./FormPageStore";
import { formatNumber } from "utils/number";
import moment from "moment";
import { setNotificationMessage } from "./AppStoreActions";

export const setStoreValue =
  (name: keyof StoreState, value) =>
  ({ setState }) => {
    setState({
      [name]: value,
    });
  };

export const setStoreValues =
  (values) =>
  ({ setState }) => {
    setState({
      ...values,
    });
  };

export const loadOffer =
  (payload, t, taxonomy, onSuccess?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.getOffer(payload);
      const flatOffer = flattenObject(res.data);
      const preparedFlatObject = prepareFlatOffer(flatOffer, t, taxonomy);
      setState({
        offer: preparedFlatObject,
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(flatOffer);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
    }
  };

export const repeatOffer =
  (payload, t, taxonomy, onSuccess?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.getOffer(payload);
      const flatOffer = flattenObject(res.data);
      const preparedFlatObject = prepareFlatOffer(flatOffer, t, taxonomy);
      setState({
        repeatOffer: {
          ...preparedFlatObject,
          InvalidatedAt: null,
          InvalidatedByUserFullName: null,
        },
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(flatOffer);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
    }
  };

export const invalidateOffer =
  (payload, onSuccess?) =>
  async ({ setState, getState }) => {
    if (getState().offerLoadStatus === "loading") return;
    try {
      setState({
        offerLoadStatus: "loading",
      });
      const res = await API.invalidateOffer(payload);
      setState({
        offerLoadStatus: "success",
      });
      onSuccess && onSuccess(res.data.appInfo.dataObj);
    } catch (error) {
      handleAxiosError(error);
      setState({
        offerLoadStatus: "error",
      });
    }
  };

export const calculate =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().calculateStatus === "loading") return;
    try {
      setState({
        calculateStatus: "loading",
      });
      const res = await API.calculate(payload);
      setState({
        calculateStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        onError && onError(message);
      });
      setState({
        calculateStatus: "error",
      });
    }
  };

export const createCertificate =
  (payload, onSuccess?, onError?) =>
  async ({ setState, getState }) => {
    if (getState().createCertificateStatus === "loading") return;
    try {
      setState({
        createCertificateStatus: "loading",
      });
      const res = await API.createCertificate(payload);
      setState({
        createCertificateStatus: "success",
      });
      onSuccess && onSuccess(res.data);
    } catch (error) {
      handleAxiosError(error, (message) => {
        onError && onError(message);
      });
      setState({
        createCertificateStatus: "error",
      });
    }
  };

function prepareFlatOffer(flatOffer: any, t: any, taxonomy: any) {
  return {
    NetPurchasingValueOfVehicle: prepareNumber(
      flatOffer.NetPurchasingValueOfVehicle
    ),
    DeductibleGeneral: prepareTaxonomy(
      flatOffer.DeductibleGeneral,
      t,
      taxonomy,
      "EHMDeductibleGeneral"
    ),
    DeductibleBrokenGlass: prepareTaxonomy(
      flatOffer.DeductibleBrokenGlass,
      t,
      taxonomy,
      "EHMDeductibleBrokenGlass"
    ),
    VehicleVin: flatOffer.VehicleVin,
    VehicleName: flatOffer.VehicleName,
    VehicleManufacturedOn: prepareDate(flatOffer.VehicleManufacturedOn),
    IsNewVehicle: flatOffer.IsNewVehicle + "",
    VehicleBrand: flatOffer.VehicleBrand,
    VehicleModel: flatOffer.VehicleModel,
    IsElectricalVehicle: flatOffer.IsElectricalVehicle + "",
    CompanyName: flatOffer.CompanyName,
    CompanyUid: flatOffer.CompanyUid,
    CompanyRegisterNumber: flatOffer.CompanyRegisterNumber,
    CompanyStreet: flatOffer.CompanyStreet,
    CompanyHouseNumber: flatOffer.CompanyHouseNumber,
    CompanyFlatNumber: flatOffer.CompanyFlatNumber,
    CompanyPostalCode: flatOffer.CompanyPostalCode,
    CompanyCity: flatOffer.CompanyCity,
    CompanyTelephone: flatOffer.CompanyTelephone,
    CompanyEmail: flatOffer.CompanyEmail,
    PolicyNumberErsteFrameContract: flatOffer.PolicyNumberErsteFrameContract,
    PolicyStartDate: prepareDate(flatOffer.PolicyStartDate),
    PolicyEndDate: prepareDate(flatOffer.PolicyEndDate),
    LeasingStartDate: prepareDate(flatOffer.LeasingStartDate),
    LeasingEndDate: prepareDate(flatOffer.LeasingEndDate),
    LeasingContractNumber: flatOffer.LeasingContractNumber,
    TerritoryOfCoverage: prepareTaxonomy(
      flatOffer.TerritoryOfCoverage,
      t,
      taxonomy,
      "EHMTerritoryOfCoverage"
    ),
    PolicyPremium: prepareNumber(flatOffer.PolicyPremium),
    OfferId: flatOffer.OfferId,
    InsurerName: flatOffer.InsurerName,
    PathToOfferPdf: flatOffer.PathToOfferPdf,
    PathToAccessStatementPdf: flatOffer.PathToAccessStatementPdf,
    Status: prepareTaxonomy(flatOffer.Status, t, taxonomy, "EHMStatus"),
    InvalidatedByUserFullName: flatOffer.InvalidatedByUserFullName,
    InvalidatedAt: prepareDate(flatOffer.InvalidatedAt),
    OfferRequestedAt: prepareDate(flatOffer.OfferRequestedAt),
    OfferRequestedByUserFullName: flatOffer.OfferRequestedByUserFullName,
  };
}
function prepareNumber(num: any) {
  return formatNumber(num);
}

function prepareTaxonomy1(value: any, t, taxonomy: any, taxonomyName: string) {
  const tax = taxonomy[taxonomyName].byId[value];
  return {
    value: tax.id,
    label: t(tax.code),
  };
}

function prepareTaxonomy(value: any, t, taxonomy: any, taxonomyName: string) {
  const tax = taxonomy[taxonomyName].byId[value];
  return tax.id;
}

function prepareDate(dateString: any) {
  return dateString ? moment(dateString).toDate() : null;
}
