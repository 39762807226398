import {
  createStore,
  createHook,
  createActionsHook,
  createStateHook,
} from "react-sweet-state";
import { defaults } from "react-sweet-state";
import { LoadStatus, Offer } from "types/types";
import {
  calculate,
  createCertificate,
  loadOffer,
  repeatOffer,
  setStoreValue,
  setStoreValues,
} from "./FormPageStoreActions";
import {
  initialDisabledMap,
  initialOffer,
  initialRequiredMap,
  initialVisibleMap,
} from "./FormPageStoreInitialValues";
defaults.devtools = true;

export type StoreState = {
  offer: Offer;
  repeatOffer: Offer;
  refreshFormItems: boolean;
  initialOffer: Offer;
  requiredMap: { [key: string]: boolean };
  disabledMap: { [key: string]: boolean };
  visibleMap: { [key: string]: boolean };
  offerLoadStatus: LoadStatus;
  createCertificateStatus: LoadStatus;
  calculateStatus: LoadStatus;
  accordionCollapsedMap: { [key: string]: boolean };
  selectedTab: string;
  disabledTab1: boolean;
  disabledTab2: boolean;
  disabledTab3: boolean;
  disabledTab4: boolean;
  disabledTab5: boolean;
};

// This is the value of the store on initialisation
const initialState: StoreState = {
  offer: null,
  repeatOffer: null,
  refreshFormItems: false,
  initialOffer: initialOffer,
  requiredMap: initialRequiredMap,
  disabledMap: initialDisabledMap,
  visibleMap: initialVisibleMap,

  calculateStatus: "none",
  offerLoadStatus: "none",
  createCertificateStatus: "none",
  accordionCollapsedMap: {
    "ehm.calculationSection.label": false,
    "ehm.vehicleSection.label": false,
    "ehm.companySection.label": false,
    "ehm.policySection.label": false,
  },
  selectedTab: "tab1",
  disabledTab1: false,
  disabledTab2: true,
  disabledTab3: true,
  disabledTab4: true,
  disabledTab5: true,
};

// All the actions that mutate the store
const actions = {
  setFormStoreValue: setStoreValue,
  setFormStoreValues: setStoreValues,
  reset:
    () =>
    ({ setState }) => {
      setState(initialState);
    },
  calculate: calculate,
  createCertificate: createCertificate,
  loadOffer: loadOffer,
  repeatOffer: repeatOffer,
};

const FormPageStore = createStore({
  initialState,
  actions,
  name: "FormPageStore",
});
export const useFormPageStore = createHook(FormPageStore);
export const useFormPageStoreState = createStateHook(FormPageStore);
export const useFormPageStoreActions = createActionsHook(FormPageStore);
