import { GrecoHeader } from "@greco/components";
import { ISettingsToggle } from "@greco/components/dist/components/GrecoHeader/GrecoPanel/Settings/Settings";
import { ActionButton, mergeStyleSets } from "office-ui-fabric-react";
import React, { useEffect, useState } from "react";
import { WithTranslation, withTranslation } from "react-i18next";
import { useAppStore } from "store/AppStore";
import { ErrorHandlerMessage } from "utils/errorHandler";
import i18n, { inContextEditorPostProcessor } from "../../i18n";
import { useNavigate, useLocation } from "react-router-dom";
import { MSGraphAuth } from "api/GraphService";
import { addAppSetting, getAppSettings, updateAppSetting } from "api/api";
import { Setting, SupportedLanguage } from "types/types";
import { IDropdownOption } from "@fluentui/react";

const languageList: { [key: string]: string } = {
  "Language.EN_GB": "en-GB",
  "Language.HU_HU": "hu-HU",
};

interface IAppHeaderProps extends WithTranslation {
  headerConfig?: any;
  user?: any;
  panelStatus?: Function;
  tooltipStatusChange?: any;
  logo?: any;
  logout?: any;
  searchOnChange?: any;
  searchOnSearch?: any;
  search?: string;
  darkMode?: any;
}
export const errorHandlerClasses = mergeStyleSets({
  contentContainer: {
    whiteSpace: "initial",
    margin: "10px 0",
    display: "flex",
    flexDirection: "column",
  },
  copyTo: {
    display: "inline-flex",
    alignItems: "center",
    justifyContent: "flex-end",
  },
  copyToIcon: { height: 17 },
  notificationMessage: {
    borderBottom: "2px solid #faf9f8",
    cursor: "pointer",
  },
});
const AppHeader = (props: IAppHeaderProps): JSX.Element => {
  const {
    headerConfig,
    user,
    panelStatus,
    tooltipStatusChange,
    logo,
    logout,
    searchOnChange,
    searchOnSearch,
    search,
    darkMode,
    t,
  } = props;

  const navigate = useNavigate();
  const location = useLocation();

  const [shouldShowPhrase, setShouldShowPhrase] = useState(false);
  const [appSettings, setAppSettings] = useState([]);

  const [{ taxonomyUad }] = useAppStore();

  const applicationCodeTaxonomy = taxonomyUad?.ApplicationCode;

  const applicationSettingsTaxonomy = taxonomyUad?.ApplicationSetting;

  const applicationCodeId = applicationCodeTaxonomy?.find(
    (app) => app.code === "Application.EHM"
  )?.id;

  const appSettingCodeId = applicationSettingsTaxonomy?.find(
    (setting) => setting.code === "AppSetting.UserSettings"
  )?.id;

  const supportedLangsTaxonomy = taxonomyUad?.SupportedLanguage;

  const phraseToggle: ISettingsToggle = {
    title: "Phrase",
    toggleProps: {
      defaultChecked:
        !!new URLSearchParams(location.search).get("phrase_context") ||
        inContextEditorPostProcessor.phraseEnabled,
      onChange: (_event, checked) => {
        if (checked) {
          inContextEditorPostProcessor.phraseEnabled = true;
        } else {
          inContextEditorPostProcessor.phraseEnabled = false;
          const queryParams = new URLSearchParams(location.search);
          if (queryParams.get("phrase_context")) {
            queryParams.delete("phrase_context");
            navigate(
              {
                pathname: location.pathname,
                search: queryParams.toString(),
              },
              { replace: true }
            );
          }

          // Reload is needed because we have to remove
          // Phrase scripts from the client
          window.location.reload();
        }
      },
    },
  };

  const [{ notifications }, { clearAllNotifications }] = useAppStore();

  const enableInContextEditor = async () => {
    const account = await MSGraphAuth.accountObj;
    if (!(account && account.idTokenClaims)) return false;

    const roles: string[] = (account.idTokenClaims as { roles: string[] })
      .roles;
    return !!(roles && roles.includes("PHRASE"));
  };

  useEffect(() => {
    (async () => {
      setShouldShowPhrase(await enableInContextEditor());
      const grecoHeader = document.querySelector(".greco-header");

      if (grecoHeader && process.env.REACT_APP_HEADER_ORANGE_COLOR === "true") {
        grecoHeader.setAttribute("style", `background: #ea4300 !important`);
      }
    })();
  }, []);

  useEffect(() => {
    if (applicationCodeId) {
      getAppSettings(Number(applicationCodeId)).then((res) => {
        setAppSettings(res.data);
      });
    }
  }, [applicationCodeId]);

  return (
    <GrecoHeader
      headerConfig={headerConfig}
      user={user}
      panelStatus={panelStatus}
      tooltipStatusChange={tooltipStatusChange}
      tooltipsStatus={headerConfig.tooltipsStatus}
      logo={logo}
      logout={logout}
      searchOnChange={searchOnChange}
      searchOnSearch={searchOnSearch}
      search={search}
      darkMode={darkMode}
      defaultLanguage={i18n.resolvedLanguage}
      onChangeLanguage={async (__event: any, languageOption?: any) => {
        if (!languageOption) return;

        const createData: any = {
          applicationCodeId: applicationCodeId,
          appSettingCodeId: appSettingCodeId,
          userAppSettingName: "Language",
          userAppSettingValue: languageOption.key,
          isDefault: false,
        };
        try {
          const userSettingsForViewOptions: any = appSettings
            .filter((item: any) => item.appSettingCodeId === appSettingCodeId)
            ?.find((userSetting: any) => userSetting.isDefault);

          if (userSettingsForViewOptions) {
            const editData = {
              applicationCodeId: userSettingsForViewOptions.applicationCodeId,
              appSettingCodeId: userSettingsForViewOptions.appSettingCodeId,
              userAppSettingId: userSettingsForViewOptions.userAppSettingId,
              userAppSettingName: userSettingsForViewOptions.userAppSettingName,
              userAppSettingDescription:
                userSettingsForViewOptions.userAppSettingDescription,
              userAppSettingValue: languageOption.key,
              isDefault: false,
            } as Setting;

            await updateAppSetting(editData);
          } else await addAppSetting(createData);

          i18n.changeLanguage(languageOption.key as string);
        } catch (err) {
          i18n.changeLanguage(languageOption.key as string);
        }
      }}
      languageOptions={
        supportedLangsTaxonomy
          ? (supportedLangsTaxonomy as SupportedLanguage[])
              ?.filter(
                (language: SupportedLanguage) =>
                  language.isGrECoLanguage &&
                  (language.code === "Language.EN_GB" ||
                    language.code === "Language.HU_HU")
              )
              .map<IDropdownOption>((language: SupportedLanguage) => ({
                key: languageList[language.code],
                text: t(language.code),
              }))
          : []
      }
      headerStrings={{
        notificationsPanelStrings: {
          panelTitle: t("header.Notifications"),
          noNotificationsTitle: t("header.NoNotificationsTitle"),
          noNotificationsSubtitle: t("header.NoNotificationsSubtitle"),
        },
        helpPanelStrings: {
          panelTitle: t("header.Help"),
        },
        settingsPanelStrings: {
          panelTitle: t("header.Settings"),
          darkModeToggleLabel: t("header.DarkMode"),
          allwaysShowTooltipsToggleLabel: t("header.AlwaysShowTooltips"),
          selectLanguageLabel: t("header.SelectLanguage"),
        },
        userSettingsPanelStrings: {
          panelTitle: t("header.UserSettings"),
          myOfficeProfileLinkText: t("header.MyOfficeProfile"),
          myAccountLinkText: t("header.UserSettings"),
          signOutLinkText: t("header.SignOut"),
        },
        searchPlaceholder: t("header.Search"),
      }}
      additionalSettingsToggles={shouldShowPhrase ? [phraseToggle] : undefined}
      showErrorAndWarningMessages={() => {
        return (
          <>
            {notifications?.map((item) => {
              return (
                <div
                  key={item.notify?.label}
                  onClick={(e) => item.handleNotificationClick(e)}
                  className={errorHandlerClasses.notificationMessage}
                >
                  <ErrorHandlerMessage
                    notify={item.notify}
                    errors={null}
                    fieldName={null}
                  />
                </div>
              );
            })}
            <ActionButton
              iconProps={{ iconName: "RingerRemove" }}
              allowDisabledFocus
              styles={{
                root: { float: "right", margin: "10px 10px 0 0" },
              }}
              onClick={() => clearAllNotifications()}
            >
              {t("greco.clear")}
            </ActionButton>
          </>
        );
      }}
      messagesCount={notifications.length}
    />
  );
};

export default withTranslation()(AppHeader);
