import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { UseFormReturn } from "react-hook-form";
import { useFormPageStore, useFormPageStoreActions } from "store/FormPageStore";
import { useTheme } from "@emotion/react";
import TaxonomyField from "components/controls9/TaxonomyField";
import NumberInputField from "components/controls9/NumberInputField";
import { resolverFn } from "utils/setYupLocale";
import * as yup from "yup";
import { calculateSchema } from "../useFormValidationSchema";
import { formatNumber } from "utils/number";

import { Spinner } from "@fluentui/react-components";

import {
  Body1,
  Button,
  Card,
  CardFooter,
  CardHeader,
  makeStyles,
  shorthands,
} from "@fluentui/react-components";
import { CalculatorRegular, VehicleSubwayRegular } from "@fluentui/react-icons";
import TextInputField from "components/controls9/TextInputField";
import { MessageBarType } from "office-ui-fabric-react";
import { useAppStoreActions } from "store/AppStore";
import { fieldToTab } from "utils/utils";
import { random } from "lodash";
import { randomInt } from "crypto";

type Props = {
  form: UseFormReturn<any, any>;
  isLoading: boolean;
};

const useStyles = makeStyles({
  card: {
    ...shorthands.margin("auto"),
    width: "768px",
    maxWidth: "100%",
    marginTop: "20px",
    marginBottom: "20px",
  },
  grid: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridGap: "10px",
  },
  gridPremium: {
    display: "grid",
    gridTemplateColumns: "repeat(2, 1fr)",
    gridTemplateRows: "repeat(2, 1fr)",
    gridGap: "10px",
  },
});

const CalculationSection = (props: Props) => {
  const isLoading = props.isLoading;
  const { setValue, getValues, setError, clearErrors, watch } = props.form;
  const { t } = useTranslation();

  const [displayPremium, setDisplayPremium] = React.useState(
    getValues("PolicyPremium") !== null
  );
  const [
    { visibleMap, disabledMap, requiredMap, calculateStatus },
    { calculate },
  ] = useFormPageStore();
  const styles = useStyles();

  const { setFormStoreValue, setFormStoreValues } = useFormPageStoreActions();
  const { setNotificationMessage, clearAllNotifications } =
    useAppStoreActions();
  const theme = useTheme();

  const labelStyle: any = (row, column) => {
    return {
      gridRow: `${row} / ${row}`,
      gridColumn: `${column} / ${column}`,
      textAlign: "right",
      marginTop: "6px",
    };
  };

  const fieldStyle = (row, column) => {
    return { gridRow: `${row} / ${row}`, gridColumn: `${column} / ${column}` };
  };

  watch((data, { name, type }) => {
    if (
      type === "change" &&
      (name === "NetPurchasingValueOfVehicle" ||
        name === "DeductibleGeneral" ||
        name === "DeductibleBrokenGlass")
    ) {
      setDisplayPremium(false);
      setFormStoreValues({
        repeatOffer: null,
        selectedTab: "tab1",
        disabledTab1: false,
        disabledTab2: true,
        disabledTab3: true,
        disabledTab4: true,
        disabledTab5: true,
      });
      // setValue("PolicyPremium", null);
      // setValue("InsurerName", null);
    }

    //onFormValueChangeCallback(data, name, type);
  });

  return (
    <>
      <Card
        className={styles.card}
        style={{
          padding: "30px",
          borderTop:
            "5px solid " +
            (getValues("InvalidatedByUserFullName")
              ? theme.palette.red
              : theme.palette.themePrimary),
        }}
      >
        <CardHeader
          header={
            <Body1>{/* <b>{t("ehm.calculationSection.label")}</b> */}</Body1>
          }
        />
        <div className={styles.grid}>
          <div style={labelStyle(1, 1)}>
            {t("ehm.NetPurchasingValueOfVehicle.label")}*
          </div>
          <div style={fieldStyle(1, 2)}>
            <NumberInputField
              name="NetPurchasingValueOfVehicle"
              label={"ehm.NetPurchasingValueOfVehicle.label"}
              form={props.form}
              required={requiredMap.NetPurchasingValueOfVehicle}
              disabled={disabledMap.NetPurchasingValueOfVehicle}
              visible={visibleMap.NetPurchasingValueOfVehicle}
              isLoading={isLoading}
              contentAfter="Ft"
            />
          </div>
          <div style={labelStyle(2, 1)}>
            {t("ehm.DeductibleGeneral.label")}*
          </div>
          <div style={fieldStyle(2, 2)}>
            <TaxonomyField
              isLoading={isLoading}
              form={props.form}
              name="DeductibleGeneral"
              label={"ehm.DeductibleGeneral.label"}
              taxonomyKey="EHMDeductibleGeneral"
              required={requiredMap.DeductibleGeneral}
              disabled={disabledMap.DeductibleGeneral}
              visible={visibleMap.DeductibleGeneral}
            />
          </div>
          <div style={labelStyle(3, 1)}>
            {t("ehm.DeductibleBrokenGlass.label")}
          </div>
          <div style={{ marginTop: "6px", ...fieldStyle(3, 2) }}>
            <TaxonomyField
              isLoading={isLoading}
              form={props.form}
              name="DeductibleBrokenGlass"
              label={"ehm.DeductibleBrokenGlass.label"}
              taxonomyKey="EHMDeductibleBrokenGlass"
              required={requiredMap.DeductibleBrokenGlass}
              disabled={disabledMap.DeductibleBrokenGlass}
              visible={visibleMap.DeductibleBrokenGlass}
            />
          </div>
        </div>
        {displayPremium ? (
          <>
            <div
              className={styles.gridPremium}
              style={{
                padding: "24px 0px",
                backgroundColor: theme.palette.neutralLighter,
              }}
            >
              <div style={labelStyle(1, 1)}>{t("ehm.PolicyPremium.label")}</div>
              <div style={{ marginTop: "6px", ...fieldStyle(1, 2) }}>
                <NumberInputField
                  label={"ehm.PolicyPremium.label"}
                  name="PolicyPremium"
                  form={props.form}
                  required={requiredMap.PolicyPremium}
                  disabled={disabledMap.PolicyPremium}
                  visible={visibleMap.PolicyPremium}
                  isLoading={isLoading}
                  contentAfter="Ft"
                />
              </div>

              <div style={labelStyle(2, 1)}>{t("ehm.InsurerName.label")}</div>
              <div style={{ marginTop: "6px", ...fieldStyle(2, 2) }}>
                <TextInputField
                  isLoading={isLoading}
                  form={props.form}
                  name="InsurerName"
                  label={"ehm.InsurerName.label"}
                  required={requiredMap.InsurerName}
                  disabled={disabledMap.InsurerName}
                  visible={visibleMap.InsurerName}
                />
              </div>
            </div>
          </>
        ) : null}
        <CardFooter style={{ flexDirection: "row-reverse", marginTop: "20px" }}>
          {displayPremium ? (
            <Button
              appearance="subtle"
              icon={<VehicleSubwayRegular fontSize={16} />}
              onClick={() => {
                clearErrors();
                clearAllNotifications();
                resolverFn(calculateSchema(yup, t), getValues()).then((res) => {
                  if (Object.keys(res.errors).length > 0) {
                    Object.keys(res.errors).forEach((key) => {
                      setError(key, res.errors[key]);
                      setNotificationMessage({
                        errors: [],
                        key: key,
                        messageBarType: MessageBarType.error,
                        fieldName: t("ehm." + key + ".label"),
                        handleNotificationClick: (e) => {
                          setFormStoreValue("selectedTab", fieldToTab(key));
                        },
                        notify: {
                          label: "label",
                          type: "error",
                          notifyCode: "notifyCode",
                          notifyText: (
                            <>
                              <b>{t("ehm." + key + ".label")}</b>
                              <br />
                              {res.errors[key].message}
                            </>
                          ),
                        },
                      });
                    });
                  } else {
                    setFormStoreValue("disabledTab2", false);
                    setFormStoreValue("selectedTab", "tab2");
                  }
                });
              }}
            >
              {t("ehm.EnterObjectInfo.label")}
            </Button>
          ) : null}
          {displayPremium ? null : (
            <Button
              disabled={isLoading || calculateStatus === "loading"}
              appearance={displayPremium ? "secondary" : "primary"}
              icon={
                calculateStatus === "loading" ? (
                  <Spinner size="tiny" />
                ) : (
                  <CalculatorRegular fontSize={16} />
                )
              }
              onClick={() => {
                clearErrors();
                clearAllNotifications();
                resolverFn(calculateSchema(yup, t), getValues()).then((res) => {
                  if (Object.keys(res.errors).length > 0) {
                    Object.keys(res.errors).forEach((key) => {
                      setError(key, res.errors[key]);
                      setNotificationMessage({
                        errors: [],
                        key: key,
                        messageBarType: MessageBarType.error,
                        fieldName: t("ehm." + key + ".label"),
                        handleNotificationClick: (e) => {
                          setFormStoreValue("selectedTab", fieldToTab(key));
                        },
                        notify: {
                          label: "label",
                          type: "error",
                          notifyCode: "notifyCode",
                          notifyText: (
                            <>
                              <b>{t("ehm." + key + ".label")}</b>
                              <br />
                              {res.errors[key].message}
                            </>
                          ),
                        },
                      });
                    });
                  } else {
                    // if passes, data is valid
                    //setFormStoreValue("disabledTab2", false);
                    calculate(
                      {
                        AdditionalCalculationInfo: {
                          NetPurchasingValueOfVehicle: parseInt(
                            getValues("NetPurchasingValueOfVehicle")
                              .replaceAll(/\s/g, "")
                              .replaceAll(",", "."),
                            10
                          ),
                          DeductibleGeneral: getValues("DeductibleGeneral"),
                          DeductibleBrokenGlass: getValues(
                            "DeductibleBrokenGlass"
                          ),
                        },
                      },
                      (retVal) => {
                        // success
                        setValue("InsurerName", retVal.InsurerName);
                        setValue(
                          "PolicyPremium",
                          formatNumber(retVal.PolicyPremium)
                        );
                        setDisplayPremium(true);

                        setFormStoreValue("visibleMap", {
                          ...visibleMap,
                          PolicyPremium: true,
                          InsurerName: true,
                        });
                      },
                      (error) => {
                        setNotificationMessage({
                          errors: [],
                          key: random() + "calculate",
                          messageBarType: MessageBarType.error,
                          fieldName: t("ehm.calculate.label"),
                          handleNotificationClick: (e) => {
                            setFormStoreValue("selectedTab", "tab1");
                          },
                          notify: {
                            label: "label",
                            type: "error",
                            notifyCode: "notifyCode",
                            notifyText: (
                              <>
                                <b>{error}</b>
                              </>
                            ),
                          },
                        });
                      }
                    );
                  }
                });
              }}
            >
              {t("ehm.calculate.label")}
            </Button>
          )}
        </CardFooter>
      </Card>
    </>
  );
};

export default CalculationSection;
